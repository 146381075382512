import { Box, Typography, Stack, FormControlLabel, Table, Divider, TableBody, TableHead, Radio, TextField, TableCell, TableRow, RadioGroup, useMediaQuery, Checkbox, styled, Button, FormControl, Select, MenuItem, InputLabel, Card, Backdrop, IconButton, Fab, ListItemIcon, ListItemText, ListSubheader, OutlinedInput, Tooltip } from "@mui/material";
import {useForm, Controller} from 'react-hook-form';
import {Unstable_NumberInput as NumberInput} from '@mui/base/Unstable_NumberInput';
import { set } from 'react-hook-form';
import { useEffect, useState } from "react";
import { evaluate, short_id } from "../../helper/util";
import { AccountTree, Add, ArrowDownward, ArrowUpward, CalendarMonth, CheckBox, CheckBoxOutlineBlank, Checklist, Delete, Edit, EditNote, FormatListBulleted, Info, InfoOutlined, MoveDown, MoveUp, Numbers, RadioButtonChecked, RadioButtonUncheckedOutlined, VisibilityOff, VisibilityOutlined } from "@mui/icons-material";
import Notification  from "../Notification";
import { useTheme } from "@emotion/react";


var likert = ["Poor", "Needs Attention", "Average", "Good", "Excellent"];

const SectionStack = styled(Stack)(({ theme }) => ({
	overflow: 'visible',
	'&.editing': {
		'& .editable': {
			color: theme.palette.primary.main,
			textDecoration: 'underline',
			textDecorationStyle: 'dashed',
			textDecorationThickness: '1px',
			textUnderlineOffset: '2px',
			cursor: 'pointer',
			'&:hover': {
				color: theme.palette.primary.dark
			}
		},
		'& .eval-block': {
			background: "#f8f8f8",
			borderRadius: theme.shape.borderRadius,
			border: `2px dashed ${theme.palette.disabled.light}`,
			'& .eval-block-header': {
				transform: 'translateY(-50%)',
			}
		},
		'&[data-question-index]': {
			borderBottom: 'dotted 1px grey'
		}
	}
})); 

export const NewItemBox = styled(Box)(({ theme }) => ({
	borderRadius: theme.shape.borderRadius,
	border: `1px dashed ${theme.palette.primary.main}`,
	padding: '8px',
	backgroundColor: theme.palette.primary.ultraLight,
	fontWeight: 'bold',
	fontSize: '1rem',
	cursor: 'pointer',
	color: theme.palette.primary.main,
	'&.editable': {
		textDecoration: 'none !important'
	},
	'&:hover': {
		backgroundColor: theme.palette.primary.lighter
	},
	'&.tight': {
		padding: '4px 8px',
		fontSize: '0.9rem'
	}
}));

export default function(props) {

	const {section, invalid, schema, answers, index, onAnswer, canEdit, onEditing, onCancel, onUpdate, onDelete, is_lead, editing, editMode, ...other} = props;
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
	const [blocks, setBlocks] = useState([]);
	
	const [editEl, setEditEl] = useState(null);
	const [editProps, setEditProps] = useState({});
	const [conditionEl, setConditionEl] = useState(null);

	const [hovered, setHovered] = useState(false);
	const [sectionName, setSectionName] = useState(section.title);
	const theme = useTheme();

	useEffect(()=>{
		//Deep copy (to support canceling edits)
		setBlocks(JSON.parse(JSON.stringify(section.blocks)));
	}, [section, editing])

	const filterBlocks = () => {			
		var blks = section.blocks.filter(b=>{
			return editMode || !b.condition || evaluate(b.condition, { ...answers, _course_code: answers?.course_code, _role: answers?.role_id, _is_lead: is_lead});
		})
		setBlocks(blks);
	}

	const recordAnswer = (e) => {	
		var block_id = e.target.closest('[block]').getAttribute('block');
		var ans = e.target.value;
		if (e.target.type == 'radio' && !isNaN(ans)) {
			ans = parseInt(ans);
		} else if (e.target.type == 'checkbox') {
			var val = e.target.value;
			var current = (answers[block_id][e.target.name] || '').split('|');
			if (e.target.checked) {
				current.push(val);
			} else {
				current = current.filter(x=>x!=val);
			}
			ans = current.join('|');
		}

		if (onAnswer)	{
			onAnswer({schema_id: section.eval_schema_id, block_id: block_id, answer_id: e.target.name, answer: ans})
			filterBlocks();
		}
	}

	useEffect(()=>{
		if (!editMode) {
			filterBlocks();
		}
	}, [answers, section]);


	//function to move an item in an array to a new index
	const moveItem = (arr, from, to) => {
		if (to !== 0 && !to) {
			return arr;
		}
		var el = arr.splice(from, 1)[0];
		arr.splice(to, 0, el);
		return arr.map((o,i)=>{
			if (o.sequence !== undefined) {
				o.sequence = i;
			}
			return o;
		});
	}

	const moveBlockDown = (i) => {
		setBlocks(moveItem(blocks, i, i+1));
	}

	const moveBlockUp = (i) => {
		setBlocks(moveItem(blocks, i, i-1));
	}

	const removeItem = (data) => {
		//e.preventDefault();
		
		var block = blocks[data?.block_index];
		if (data.item_type == "block") { //No block == removing a block
			blocks.splice(data.block_index, 1);
		} else if (data.item_type == 'likert_rating') {
			var labels = block.likert_labels?.split('|') || likert;
			labels.splice(data.order, 1);
			block.likert_labels = labels.join('|');
		} else if (data.item_type == 'metric') {
			block.questions.splice(data.order, 1);
		
		} else if (data.item_type == 'choice') {
			block.questions[data.question_index].choices.splice(data.order, 1);
		} else if (data.question_index) {
			//remove question by index
			block.questions.splice(data.question_index, 1);			
		}
		
		setBlocks(blocks);
		setEditProps({});
		setEditEl(null);
	}

	const addLikertMetric = (data) => {
		var block = blocks[data.block_index];
		var metric = {prompt: data.prompt, label: data.label, question_id: short_id(true)};

		//insert metric at index
		block.questions.splice(data.new_order, 0, metric);
	}

	const addLikertColumn = (data) => {
		var block = blocks[data.block_index];
		var labels = block.likert_labels?.split('|') || likert;

		//insert label at index
		labels.splice(data.new_order, 0, data.prompt);
		block.likert_labels = labels.join('|');
	}

	//Used in editor when clicking an editable property
	const beginEditing = (e) => {
		var editable = e.target.closest('.editable');
		if (editing && editable) {
			var block_index = editable.closest('[data-block-index')?.getAttribute('data-block-index');
			var question_index = editable.closest('[data-question-index')?.getAttribute('data-question-index');
			var question_id = editable.closest('[data-question-index')?.getAttribute('data-question-id');
			var property = editable.getAttribute('edit_property');
			var sub_property = editable.getAttribute('edit_sub_property');
			var prop_type = editable.getAttribute('edit_prop_type');
			var order = editable.getAttribute('edit_order') || editable.closest('[edit_order]')?.getAttribute('edit_order');
			var prompt = editable.getAttribute('edit_prompt');
			var items = editable.getAttribute('edit_items') || editable.closest('[edit_items]')?.getAttribute('edit_items');
			var new_item = editable.getAttribute('edit_new_item');
			var label = editable.getAttribute('edit_label');

			var props = {
				block_index: block_index,
				block: editable.closest('[data-block-index')?.getAttribute('block'),
				label: label,
				question_index: question_index,
				question_id: question_id,
				property: property,
				sub_property: sub_property,
				item_type: prop_type,
				block_type: 'mixed',
				prompt: new_item == "1" ? "" : (prompt || e.target.innerText),
				order: order,
				new_order: order,
				items: items,
				new_item: new_item
			}

			switch (props.item_type) {
				case "block":
					props.promptLabel = "Introduction / Instructions";
					break;
				case "metric":
					props.promptLabel = "Question / Prompt";
					break;
				case "likert":
					if (new_item != "1") {
						var b = blocks[block_index];
						props = {...props, ...b};
					}
					props.promptLabel = "Question / Prompt";
					break;
				case "likert_rating":
					props.promptLabel = "Rating Label";
					break;
				case "question":
					if (new_item != "1") {
						var q = blocks[block_index].questions[order];
						props = {...props, ...q};
					} else {
						props.type = "text";
					}
					
					props.promptLabel = "Question / Prompt";
					break;
			}

			setEditProps(props);
			setEditEl(e.target);
		}
	}

	const saveEditProps = (data) => {
		

		var block = blocks[data.block_index];
		try {
			if (data.item_type == 'likert_rating') {
				if (data.new_item == "1") {
					addLikertColumn(data);
				} else {
					var labels = block.likert_labels?.split('|') || likert;
					labels[data.order] = data.prompt;
					//reorder labels based on order and new value
					if (data.new_order > -1 && data.new_order != data.order) {
						moveItem(labels, data.order, data.new_order)
					}					
					block.likert_labels = labels.join('|');
				}
			} else if (data.item_type == 'metric') {
				if (data.new_item == "1") {
					addLikertMetric(data);
				} else {
					block.questions[data.order].prompt = data.prompt;
					block.questions[data.order].label = data.label;
					if (data.new_order > -1 && data.new_order != data.order) {
						moveItem(block.questions, data.order, data.new_order);
					}
				}
				
			} else if (data.item_type =='block' || data.item_type == 'likert') {
				if (data.new_item == "1") {
					//Add new block
					var new_block = {block_id: short_id(true), eval_section_id: section.id, label: data.label, sequence: data.new_order, required: data.required, prompt: data.prompt, type: data.block_type, questions: []};
					blocks.push(new_block);
					moveItem(blocks, blocks.length-1, data.new_order);
				} else {
					block.prompt = data.prompt;
					block.label = data.label;
					block.required = data.required;
					if (data.new_order > -1 && data.new_order != data.order) {
						moveItem(blocks, data.block_index, data.new_order);
					}
				}
			} else if (data.item_type == 'question') {
				if (data.new_item == "1") {
					//Add new question
					var new_question = {prompt: data.prompt, label: data.label, required: false, question_id: short_id(true), type: data.type, answer_id: `Q${block.questions.length+1}`};
					if (data.type == "radio" || data.type == "multi-select") {
						new_question.choices = [{code: "Sample", label: "Sample Choice"}];
						new_question.allow_other = data.allow_other;
					}
					
					block.questions.push(new_question);
					moveItem(block.questions, block.questions.length-1, data.new_order);
				} else {
					block.questions[data.order].prompt = data.prompt;
					block.questions[data.order].label = data.label;
					block.questions[data.order].required = data.required || false;
					block.questions[data.order].allow_other = data.allow_other || false;
					if (data.new_order > -1 && data.new_order != data.order) {
						moveItem(block.questions, data.order, data.new_order);
					}
				}
			} else if (data.item_type == 'choice') {
				if (data.new_item == "1") {
					var q = block.questions[data.question_index];
					q.choices.push({label: data.prompt, code: data.label});
					moveItem(q.choices, q.choices.length-1, data.new_order);
				} else {
					block.questions[data.question_index].choices[data.order].label = data.prompt;
					block.questions[data.question_index].choices[data.order].code = data.label;
					if (data.new_order > -1 && data.new_order != data.order) {
						moveItem(block.questions[data.question_index].choices, data.order, data.new_order);
					}
				}
			} else if (data.item_type == 'section') {
				section.title = data.prompt;
				section.new_sequence = data.new_order;
				//onUpdate({title: section.title, sequence: data.new_order});
			} else {
				if (block?.type == "likert" && data.new_item == "1") {
					
				} else if (data.new_item == "1") {
				
				} else {
					block.prompt = data.prompt; 
				}
			}
		} finally {
			setBlocks(blocks);
		
			setEditProps({});
			setEditEl(null);
		}

	}

	const WhiteInput = styled(TextField)(({ theme }) => ({
		'& .MuiInputBase-root': {
			backgroundColor: 'rgba(255, 255, 255, 0.5)'
		}
	}));
	
	return section && (answers || editMode) ? <Box position="relative" {...other} onMouseEnter={()=>!editing && setHovered(true)} onMouseLeave={()=>!editing && setHovered(false)}>
		
		<SectionStack direction="column" spacing={2}  pb={0} className={editing ? "editing" : ""} onClick={beginEditing} >
		
		{editMode && <Stack direction="row" alignItems="center" p={1} sx={{backgroundColor: editing ? theme.palette.primary.main : "#ccc", position: editing ? 'sticky' : 'static', top: 0, zIndex: 1000}}>
			<Box flex={1}>
				<Typography variant="h6" color={editing ? "#fff" : "black"} display="inline"  >Section {section.sequence+1}: </Typography><Typography variant="h6" className="editable" edit_prop_type="section" edit_items={schema.sections.length} edit_order={index} display="inline" sx={{color: editing ? "#fff !important" : "black !important"}}>{section.title}</Typography>
			</Box>
			{canEdit && !editing && <Stack direction="row" spacing={1}>
				<Button size="small" variant="contained" onClick={()=>onEditing(section.id)}>Edit Section</Button>
				<Button size="small" variant="contained" color="error" onClick={()=>onDelete(section.id)} startIcon={<Delete/>}>Delete Section</Button>
			</Stack>}
			{editing && <Stack direction="row" spacing={2}>
				<Button size="small" color="white" variant="outlined" onClick={()=>{setBlocks(section.blocks); onCancel();}}>Cancel</Button>
				<Button size="small" color="white" variant="contained" sx={{color: theme.palette.primary.main}} onClick={()=>onUpdate({title: section.title, new_sequence: section.new_sequence, blocks: blocks})}>Apply Changes</Button>
			</Stack>}
		</Stack>}
		<Stack p={editing ? 2 : 0} spacing={editing ? 6 : 2} sx={{zIndex: 1}}>
			{blocks.map((block, i) => {
				if (block.eval_section_id != section.id) {
					return <></>;
				}
				return <Box key={i} className="eval-block" edit_order={block.sequence} block={block.block_id} data-block-index={i} edit_items={blocks.length} position="relative">
					
					{editing && <Stack direction="row" alignItems="center" justifyContent="center" className="eval-block-header">
						<Card variant="outlined" sx={{padding: '0px'}}>
							<Stack direction="row" alignItems="center" spacing={0}>
								<Tooltip title="Block Details" arrow placement="top">
									<Button onClick={beginEditing} className="editable" edit_prop_type="block"  edit_prompt={block.prompt} edit_label={block.label} >{block.label}</Button>
								</Tooltip>
								<Divider orientation="vertical" flexItem />
								{(i > 0 || section.sequence > 0) && <Tooltip title="Conditions" arrow placement="top">
									<Button onClick={(e)=>{e.stopPropagation(); setConditionEl({block: block, section: section, el: e.currentTarget})}} sx={{minWidth:0}}>{!!block.condition ? <VisibilityOff/> : <VisibilityOutlined />}</Button>
								</Tooltip>}
								{(i > 0 || section.sequence > 0) && <Divider orientation="vertical" flexItem />}
								{i < blocks.length-1 && <Tooltip title="Move Block Down" arrow placement="top">
									<Button sx={{minWidth:0}} onClick={()=>moveBlockDown(i)}><MoveDown /></Button>
								</Tooltip>}
								{i > 0 && <Tooltip title="Move Block Up" arrow placement="top">
									<Button onClick={()=>moveBlockUp(i)} sx={{minWidth:0}}><MoveUp /></Button>
								</Tooltip>}
								
								<Tooltip title="Delete Block" arrow placement="top">
									<Button onClick={(e)=>{setEditProps({type:'block', block_index: i}); removeItem({item_type:'block', block_index: i});}} sx={{minWidth:0}} color="error"><Delete /></Button>
								</Tooltip>
							</Stack>
						</Card>
					</Stack>}
					
					{/* {i > 0 &&<Divider sx={{mb:2}}></Divider>} */}
					 
					{block.instructions}

					{block.type == "likert" && <Box pt={1}>
						{isMobile && <Box p={2} pt={0}><Typography ml={1} className="prompt editable" edit_order="" edit_prop_type="block">{block.prompt}</Typography></Box>}
						
						<Table className="likert-table" size="small">

							{/* LIKERT COLUMNS (NON-MOBILE) */}
							{!isMobile && <TableHead sx={{verticalAlign:"bottom"}}>
								<TableRow >
									<TableCell className="editable" verticalAlign="bottom" sx={{verticalAlign:"bottom"}}>
										{block.prompt && <Box sx={{fontWeight: 'bold', px:0}}>
										<Typography ml={1} fontWeight="bold" className="prompt editable" edit_prop_type="likert" edit_order={i} edit_label={block.label} edit_items={blocks.length}>{block.prompt}</Typography>
										</Box>}
									</TableCell> 
									{(block.likert_labels?.split('|') || likert).map((label, j) => {
										return !isMobile && <TableCell width="10%" key={j} align="center" sx={{verticalAlign:"bottom"}} className="editable" edit_prop_type="likert_rating" edit_order={j} edit_sub_property={j} edit_items={(block.likert_labels?.split('|') || likert).length}>{label}</TableCell>;
									})}
									{editing && <TableCell width="10%" align="center" sx={{verticalAlign:"bottom"}} ><NewItemBox className="editable" edit_new_item="1" edit_order={(block.likert_labels?.split('|') || likert).length} edit_items={(block.likert_labels?.split('|') || likert).length+1} edit_prop_type="likert_rating">+ Add</NewItemBox></TableCell>}
								</TableRow>

							</TableHead>}

							{/* LIKERT METRICS */}
							<TableBody>
									
								{block.questions.map((question, j) => {
									return <TableRow key={j} className={invalid[block.block_id] && invalid[block.block_id][question.answer_id] ? 'invalid-answer' : ''} data-question-index={j} data-question-id={question.question_id} sx={{backgroundColor: j % 2 ?'#f6f6f6' : ''}}>
										<TableCell>
											<Typography sx={{pl:1}} fontSize="0.9rem" className="editable" edit_prop_type="metric" edit_label={question.label} edit_order={j} edit_items={block.questions.length}>{question.prompt}</Typography>
											{isMobile && <Box > 
											
												{(block.likert_labels?.split('|') || likert).map((label, k) => {
													return <Box key={k} sx={{display:"flex", alignItems:"center", justifyContent:"flex-start", py:0}}>
														<FormControlLabel control={<Radio
															checked={!editMode && answers[block.block_id][question.answer_id] == k+1}
															onChange={recordAnswer}
															value={k+1}
															name={question.answer_id}
															sx={{py:0.5, pl:6, pr:1}}
														/>} 
													label={label} />
													
												</Box>;
											})}</Box>}
										</TableCell>
										
										{!isMobile && (block.likert_labels?.split('|') || likert).map((label, k) => {
											
											return <TableCell key={k} align="center">
												<Radio
													disabled={editMode}
													checked={!editMode && answers[block.block_id][question.answer_id] == k+1}
													onChange={recordAnswer}
													value={k+1}
													name={question.answer_id}
												/>
											</TableCell>;
										})}	

										{editing && <TableCell align="center"></TableCell>}
										
									</TableRow>
								})}

								{/* EDIT MODE: ADD LIKERT METRIC */}

								{editMode && editing && <TableRow className="editable" sx={{'& td': {backgroundColor: `${theme.palette.primary.ultraLight} !important`}, '&:hover td': {cursor: 'pointer', backgroundColor: `${theme.palette.primary.lighter} !important`}}} edit_new_item="1" edit_prop_type="metric" edit_order={block.questions.length} edit_items={block.questions.length+1}>
										<TableCell  >
											<Box pl={1} color={theme.palette.primary.main}>+ New Metric</Box>
										</TableCell>
										{(block.likert_labels?.split('|') || likert).map((label, k) => {
											
											return <TableCell key={k} align="center">
												<Radio
													disabled={true}
													checked={false}
													value={k+1}
													sx={{opacity: 0.75}}
												/>
											</TableCell>;
										})}
										<TableCell></TableCell>
									</TableRow>}

							</TableBody>
						</Table>
					</Box>}
					
					{(block.type == "mixed" || block.type == null) && <Stack direction="column" spacing={1} alignItems="flex-start">
						{block.questions.map((question, j) => {
							return <Box py={1.5} mt="0 !important" key={question.id} data-question-id={question.question_id} data-question-index={j} alignSelf="stretch">
								{question.type=="multiline" && <Box sx={{px:2}} key={j} className={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && 'invalid-answer'}>
									{question.prompt && <Typography className="editable" edit_prop_type="question" edit_order={j} edit_items={block.questions.length}>{question.prompt}</Typography>}
									<TextField multiline disabled={editMode} error={invalid[block.block_id] && invalid[block.block_id][question.answer_id]} helperText={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && "Required"}  rows={4} sx={{width: "100%", mt:1}} name={question.answer_id} value={editMode ? "" : answers[block.block_id][question.answer_id]} onChange={recordAnswer}></TextField>
								</Box>}

								{question.type == "date" && <Stack direction="column" spacing={2} key={j} className={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && 'invalid-answer'}>
									<Box sx={{px:2}} >
											{question.prompt && <Typography className="prompt editable" edit_prop_type="question" edit_order={j} edit_items={block.questions.length}>{question.prompt}</Typography>}
											<TextField  disabled={editMode} size="small" error={invalid[block.block_id] && invalid[block.block_id][question.answer_id]} helperText={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && "Required"} type="date" sx={{width: 180, mt:1}} name={question.answer_id} value={editMode ? "" : answers[block.block_id][question.answer_id]} onChange={recordAnswer}></TextField>
										</Box>
								</Stack>}

								{question.type == "text" && <Stack direction="column" spacing={2} key={j} className={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && 'invalid-answer'}>
									<Box sx={{px:2}} >
											{question.prompt && <Typography className="prompt editable" edit_prop_type="question" edit_order={j} edit_items={block.questions.length}>{question.prompt}</Typography>}
											<TextField disabled={editMode} size="small" error={invalid[block.block_id] && invalid[block.block_id][question.answer_id]} helperText={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && "Required"} sx={{width: "100%", mt:1}} name={question.answer_id} value={editMode ? "" : answers[block.block_id][question.answer_id]} onChange={recordAnswer}></TextField>
										</Box>
								</Stack>}

								{question.type == "number" && <Stack direction="column" spacing={2} key={j} className={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && 'invalid-answer'}>
									<Box sx={{px:2}} >
											{question.prompt && <Typography className="prompt editable " edit_prop_type="question" edit_order={j} edit_items={block.questions.length}>{question.prompt}</Typography>}
											{/* <NumberInput disabled={editMode} size="small" error={invalid[block.block_id] && invalid[block.block_id][question.answer_id]} helperText={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && "Required"} sx={{width: 90, mt:1}} name={question.answer_id} value={editMode ? "" : answers[block.block_id][question.answer_id]} onChange={recordAnswer}></NumberInput> */}
											<TextField disabled={editMode} size="small" type="number" error={invalid[block.block_id] && invalid[block.block_id][question.answer_id]} helperText={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && "Required"} inputProps={{"type": "number"}} sx={{width: 90, mt:1}} name={question.answer_id} value={editMode ? "" : answers[block.block_id][question.answer_id]} onChange={recordAnswer}></TextField>
										</Box>
								</Stack>}

								{question.type == "radio" && <Stack direction="column" spacing={2} key={j} className={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && 'invalid-answer'}>
									<Box  alignSelf="flex-start" >
										{question.prompt &&<Box sx={{px:2}} >
											<Typography className="prompt editable" edit_prop_type="question" edit_order={j} edit_items={block.questions.length}>{question.prompt}</Typography>
										</Box>}

										<Box sx={{px:2, pl:4, mt: '0 !important'}} mt={0} >
											<Stack direction="column" className="radio" alignItems="flex-start">
												{question.choices.map((choice, k) => {
													return <FormControlLabel key={k} sx={{mt:1}} className="editable" disableTypography edit_label={choice.code} edit_prop_type="choice" edit_order={k} edit_items={question.choices.length} control={<Radio
														disabled={editMode}
														checked={!editMode && answers[block.block_id][question.answer_id] == choice.code}
														onChange={recordAnswer}
														value={choice.code}
														name={question.answer_id}
														sx={{py:0.5, pr:1}}
													/>} label={choice.label} />
												})}

												{editing && <NewItemBox  ml={"-11px"} mt={1} py={1} display="inline-flex" width="auto" alignItems="center" className="editable tight" edit_prop_type="choice" edit_new_item="1" edit_items={question.choices.length+1} edit_order={question.choices.length} >
													<RadioButtonUncheckedOutlined sx={{mr:1}}/> New Choice
												</NewItemBox>}

												{!!question.allow_other && <Stack direction="row" sx={{mt:1}} >
													<FormControlLabel  control={<Radio
														disabled={editMode}
															checked={!editMode && answers[block.block_id][question.answer_id] == "OTHER"}
															onChange={recordAnswer}
															value="OTHER"
															name={question.answer_id}
															sx={{py:0.5, pr:1}}
														/>} label="Other" />
														{!editMode && answers[block.block_id][question.answer_id]?.split('|').includes("OTHER") &&
															<TextField disabled={editMode} size="small" className="other-text" sx={{mt: 1, width: 90, mt:1}} name={`${question.answer_id}_OTHER`} value={!editMode && answers[block.block_id][question.answer_id+'_OTHER']} onChange={recordAnswer}></TextField>
														}
													</Stack>}
												
											</Stack>
										</Box>
									</Box>
								</Stack>}

								{(question.type == "multi-select") && <Stack direction="column" spacing={2} key={j} className={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && 'invalid-answer'}>
									<Box sx={{px:2}} alignSelf="flex-start" >
										{question.prompt && <Typography className="prompt editable" edit_prop_type="question" edit_label={question.label} edit_order={j} edit_items={block.questions.length}>{question.prompt}</Typography>}
										<Box sx={{px:2, pl:2}} >
											<Stack direction="column" className="checkbox" alignItems="flex-start">
												{question.choices.map((choice, k) => {
													return <FormControlLabel sx={{mt:1}} key={k} className="editable" disableTypography edit_label={choice.code} edit_prop_type="choice" edit_order={k} edit_items={question.choices.length} control={<Checkbox
														disabled={editMode}
														checked={!editMode && answers[block.block_id][question.answer_id]?.split('|').includes(choice.code)}
														onChange={recordAnswer}
														name={question.answer_id}
														sx={{py:0.5, pr:1}}
														inputProps={{value: choice.code}}
													/>} label={choice.label} />
												})}

												{editing && <NewItemBox ml={"-11px"} mt={1} py={1} display="inline-flex" width="auto" alignItems="center" className="editable tight" edit_prop_type="choice" edit_new_item="1" edit_items={question.choices.length+1} edit_order={question.choices.length} >
													<CheckBoxOutlineBlank sx={{mr:1}}/> New Choice
												</NewItemBox>}

												{!!question.allow_other && <Stack sx={{mt:1}} direction="row">
													<FormControlLabel control={<Checkbox
														disabled={editMode}
														checked={!editMode && answers[block.block_id][question.answer_id]?.split('|').includes("OTHER")}
														onChange={recordAnswer}
														inputProps={{value: "OTHER"}}
														name={question.answer_id}
														sx={{py:0.5, pr:1}}
													/>} label="Other" />
													{!editMode && answers[block.block_id][question.answer_id]?.split('|').includes("OTHER") &&
														<TextField size="small" disabled={editMode} className="other-text" placeholder="Please describe..." sx={{width: 90, mt:1}} name={`${question.answer_id}_OTHER`} value={!editMode && answers[block.block_id][question.answer_id+'_OTHER']} onChange={recordAnswer}></TextField>
													}
												</Stack>}
											</Stack>
										</Box>
									</Box>
								</Stack>}

								{(question.type == "yes_no" || question.type == "yes-no") && <Stack direction="column" spacing={2} key={j} className={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && 'invalid-answer'}>
									<Box sx={{px:2}} >
										{question.prompt && <Typography className="prompt editable" edit_prop_type="question" edit_label={question.label} edit_order={j} edit_items={block.questions.length}>{question.prompt}</Typography>}
										<Box sx={{px:2, pl:2}} >
											<Stack direction="row" className="yes-no" alignItems="flex-start">													
												<FormControlLabel control={<Radio
														disabled={editMode}
														checked={!editMode && answers[block.block_id][question.answer_id] == "Yes"}
														onChange={recordAnswer}
														value="Yes"
														name={question.answer_id}
														sx={{py:0.5, pr:1}}
													/>} label="Yes" />
												<FormControlLabel control={<Radio
														disabled={editMode}
														checked={!editMode && answers[block.block_id][question.answer_id] == "No"}
														onChange={recordAnswer}
														value="No"
														name={question.answer_id}
														sx={{py:0.5, pr:1}}
													/>} label="No" />
											</Stack>
											
										</Box>
									</Box>
								</Stack>}

								
							</Box>;
						})}

						{editing && <Box px={2} alignSelf="stretch" pb={2}><NewItemBox  textAlign="center" className="editable" edit_prop_type="question" edit_new_item="1" edit_items={block.questions.length+1} edit_order={block.questions.length} >
							<Divider  textAlign="center" variant="middle" >+ New Question</Divider>
						</NewItemBox></Box>}
					</Stack>}

					{block.type == "text" && <Box p={2} className="editable" edit_prop_type="block">{block.prompt}</Box>}


				</Box>
			})}
			
			{editMode && editing && <NewItemBox className="editable" sx={{p:3, mt:2, borderWidth: 2}} edit_new_item={1} edit_items={blocks.length+1} edit_order={blocks.length} edit_prop_type="block" ><Divider  textAlign="center" variant="middle" >+ New Block</Divider></NewItemBox>}

		</Stack>


		

		{editMode && <Notification open={!!editEl} anchorEl={editEl} onClose={()=>setEditEl(null)} minWidth={400} maxWidth={600} background="#fff" color="#000" over={true} >
			<PropsEditor editProps={editProps} onSave={saveEditProps} onClose={()=>setEditEl(null)} onRemove={removeItem}/>
		</Notification>}

		{editMode && <Notification open={!!conditionEl} anchorEl={conditionEl?.el} onClose={()=>setConditionEl(null)} minWidth={400} maxWidth={800} background="#fff" color="#000" over={true} >
			<ConditionEditor schema={schema} block={conditionEl?.block} section={conditionEl?.section} onChange={(condition)=>conditionEl.block.condition=condition} />

		</Notification>}
	</SectionStack></Box> : <></>;
}

const PropsEditor = function(props) {
	
	const {editProps, onSave, onClose, onRemove} = props;

	const [showInfo, setShowInfo] = useState(false);

	const {handleSubmit, watch, control, formState: {errors}} = useForm({
		defaultValues: {
			required: false,
			...editProps,
			new_order: editProps.order
		}
	}); 

	return <form onSubmit={handleSubmit(onSave)} noValidate >
		<Stack direction="column" spacing={2} pt={1}>

			{watch("item_type") == "question" && watch("new_item") == "1" && <FormControl>
				<InputLabel size="small" id="qtype-label">Question Type</InputLabel>
				<Controller name="type" control={control} rules={{required: true}} render={({field})=>(
					<Select {...field} MenuProps={{dense:true}} error={!!errors.type} helperText={errors.type?.message} sx={{'& .MuiSelect-select': {display:'flex', alignItems: 'center'}, '& .MuiListItemText-root': {marginTop: 0, marginBottom: 0}}} size="small"  labelId="qtype-label" fullWidth label="Question Type" variant="outlined">
						<MenuItem value="text" prepend>
							<ListItemIcon >
								<Edit />
							</ListItemIcon>
							<ListItemText>
								Text
							</ListItemText>
						</MenuItem>
						<MenuItem value="multiline">
							<ListItemIcon>
								<EditNote />
							</ListItemIcon>
							<ListItemText>
								Multi-line text
							</ListItemText>
						</MenuItem>
						<MenuItem value="number">
							<ListItemIcon>
								<Numbers />
							</ListItemIcon>
							<ListItemText>
								Number
							</ListItemText>
						</MenuItem>
						<MenuItem value="date">
							<ListItemIcon>
								<CalendarMonth />
							</ListItemIcon>
							<ListItemText>
								Date
							</ListItemText>
						</MenuItem>
						<MenuItem value="yes_no">
							<ListItemIcon>
								<CheckBox/>
							</ListItemIcon>
							<ListItemText>
								Yes / No
							</ListItemText>
						</MenuItem>
						<MenuItem value="radio">
							<ListItemIcon>
								<FormatListBulleted />
							</ListItemIcon>
							<ListItemText>
								Single-selection List
							</ListItemText>
						</MenuItem>
						<MenuItem value="multi-select">
							<ListItemIcon>
								<Checklist />
							</ListItemIcon>
							<ListItemText>
								Multiple-selection list
							</ListItemText>
						</MenuItem>
					</Select>
				)}></Controller>
			</FormControl>}			

			{["block", "choice", "question", "metric"].includes(watch("item_type")) && <FormControl>
				<Controller name="label" control={control} rules={{required: true}} render={({field})=>(
					<TextField size="small" {...field} error={!!errors.label} required name="label" label="Label / Name" fullWidth variant="outlined" helperText="A short name for this item" />
				)}></Controller>
			</FormControl>}

			<Box flex={1}>
				<Controller name="prompt" control={control} rules={{required: true}} render={({field})=>(
					<TextField size="small" {...field} error={!!errors.prompt} helperText={errors.prompt?.message} name="prompt" autoFocus fullWidth label={watch("promptLabel")} variant="outlined" />
				)}></Controller>
			</Box>
						

			{watch("new_item") && watch("item_type") == "block" && <FormControl>
				<InputLabel id="block-type-label">Type</InputLabel>
				<Controller name="block_type" control={control} rules={{required: true}} render={({field})=>(
					<Select size="small" {...field} fullWidth label="Type" variant="outlined" error={!!errors.block_type} helperText={errors.block_type?.message}>
						<MenuItem value="text">Text / Instructions</MenuItem>
						<MenuItem value="mixed">Questions</MenuItem>
						<MenuItem value="likert">Likert Scale</MenuItem>
					</Select>)} ></Controller>
			</FormControl>}

			{["metric", "likert_rating", "block", "question", "choice", "section"].includes(watch("item_type")) && <FormControl>
				<FormControl>
					<InputLabel size="small" id="order-label">Order</InputLabel>
					<Controller name="new_order" control={control} rules={{required: true}} render={({field})=>(
						<Select size="small" {...field} labelId="order-label" fullWidth label="Order" variant="outlined" error={!!errors.new_order} helperText={errors.new_order?.message}>
							{Array.from({length: watch("items")}).map((x, i) => {
								return <MenuItem key={i} value={i}>{i+1}</MenuItem>
							})}
						</Select>)} ></Controller>
				</FormControl>
			</FormControl>}

			{["radio","multi-select"].includes(watch("type")) && <FormControl sx={{paddingLeft: 1, '& .MuiCheckbox-root':{paddingTop:'0 !important', paddingBottom:0}}}>
				<Controller name="allow_other" control={control}  render={({field})=>(
					<FormControlLabel label="Allow freeform entry (Other)"  control={<Checkbox checked={watch("allow_other")} size="small" {...field}  />}></FormControlLabel>
				)}></Controller>
			</FormControl>}

			{["question", "likert"].includes(watch("item_type")) && <FormControl sx={{paddingLeft: 1, '& .MuiCheckbox-root':{paddingTop:'0 !important', paddingBottom:0}}}>
				<Controller name="required" control={control}  render={({field})=>(
					<FormControlLabel label="Require a Response" control={<Checkbox size="small" checked={watch("required")} {...field} />}></FormControlLabel>
				)}></Controller>
			</FormControl>}

			{showInfo && <Box textAlign="right" className="sub-title xs">
				<Box>
					Block ID: {watch("block")}
				</Box>
				{watch("question_id") && <Box>
					Question ID: {watch("question_id")}
				</Box>}
			</Box>
}

			<Stack direction="row" spacing={2} >
				<Box flex={1}>
					<Button size="small" onClick={onClose} color="disabled">Cancel</Button>
				</Box>
				{watch("new_item") !== "1" && watch("item_type") != "section" && <IconButton size="small" sx={{opacity: 0.75}} onClick={()=>setShowInfo(!showInfo)}><InfoOutlined/></IconButton>}
				{watch("new_item") !== "1" && <Button size="small" variant="contained" color="error" startIcon={<Delete/>} onClick={()=>onRemove(watch())}>Delete</Button>}
				<Button size="small" type="submit" variant="contained" color="primary">Save</Button>
			</Stack>
		</Stack>


	</form>;
}

const ConditionEditor = function(props) {
	const {schema, block, section, onChange} = props;

	const [questions, setQuestions] = useState([]);
	const [choices, setChoices] = useState([]);
	const [condition, setCondition] = useState("");
	const {handleSubmit, setValue, reset, control, register, watch, formState: {isDirty, errors}} = useForm({
		defaultValues: {
			field: "",
			equality: "==",
			values: []
		}
	});

	const theme = useTheme();

	useEffect(() => {
		
		if (schema && section && block) {	
			setCondition(block.condition);

			let sections = schema.sections.filter(x=>x.sequence <= section.sequence).map((s,i) => {
				let blocks = s.blocks.filter(b=>s.sequence < section.sequence || b.sequence < block.sequence).map((b) => {
					
					let qs = b.questions.map((q) => {
						if (!["radio", "multi-select", "yes_no"].includes(q.type) && b.type != "likert") {
							return;
						}

						let choices = q.choices;
						if (b.type == "likert") {
							choices = (b.likert_labels?.split("|") || likert).map((l, i) => {
								return {label: l, code: (i+1).toString()};
							});
						} else if (q.type == "yes_no") {
							choices = [{label: "Yes", code: "Yes"}, {label: "No", code: "No"}];
						}
						return {
							section_label: s.title,
							block_label: b.label,
							block: b.block_id,
							label: q.label,
							question: q.question_id,
							type: q.type,
							choices: choices,
							allow_other: q.allow_other
						};
					}).filter(x=>!!x);
					var questions = qs.filter(x=>!!x);	
					if (questions.length) {
						return {
							block: b.block_id,
							label: b.label,
							questions: qs
						}
					}
					return null;
				}).filter(x=>!!x);

				if (blocks.length) {
					return {
						section: s.id,
						label: s.title,
						blocks: blocks
					};
				}
				
				return null;
			}).filter(x=>!!x);

			let qs = sections.reduce((acc, s, i) => {
				s.blocks.forEach((b,j) => {
					if (j == 0) {
						acc.push({value:`s-${i+1}`, label: s.label});
					}
					b.questions.forEach((q,k) => {	
						if (k == 0) {
							acc.push({value:`b-${i+1}-${j+1}`, label: b.label});
						}
						acc.push({value: `${q.block}['${q.question}']`, ...q});
					});
				});
				return acc;
			}, []);

			setQuestions(qs);
		}

		if (block?.condition && schema) {

			//Support two formats:
			// 1. ['val','val','val'].includes(block.question)
			// 2. block.question == 'val' || block.question == 'val' || block.question == 'val'

			var matches = block.condition.match(/^\!?\[.+?\]\.includes\(.+?\)$/ig)
			if (matches && matches.length) {
				var vals = block.condition.match(/(?:\')(?<v>.+?)(?:\')/ig).map(v=>v.replace(/[\']/g, ''));
				var field = block.condition.match(/\.includes\((?<field>.+?)(\?\.toString\(\))?\)/).groups?.field;
				
				//corrects an old format that used dot notation.  Doesn't work if field name starts with a number.
				if (!!field.match(/^[a-zA-Z0-9\_]+\.[a-zA-Z0-9\_]+$/ig)) {
					var parts = field.split('.');
					field = `${parts[0]}['${parts[1]}']`;
				}

				if(field && vals.length) {
					reset({
						field: field,
						equality: block.condition.startsWith("!") ? "!=" : "==",
						values: vals
					});
				}
			} else {
				matches = block.condition.matchAll(/^(?<field>.+?)\s*(?<eq>[!=]=)\s*\'(?<val>.+?)\'$/ig);

				let arr = matches.toArray();
				if (arr.length > 0) {
					var field = arr[0].groups.field;
					var eq = arr[0].groups.eq;
					var vals = arr.map(a=>{
						return a.groups.val;
					});

					if (!!field.match(/^[a-zA-Z0-9\_]+\.[a-zA-Z0-9\_]+$/ig)) {
						var parts = field.split('.');
						field = `${parts[0]}['${parts[1]}']`;
					}

					if (vals.length == 1 && vals[0].includes("||")) {
						vals = vals[0].match(/[^\'](?<val>\S+?)[\'$]/ig).map(v=>v.replace(/[\']/g, '')).map(v=>v.trim());
					}
					
					reset({
						field: field,
						equality: eq,
						values: vals
					});
				}
			}
		}

	}, [block]);

	useEffect(() => {
		if (watch('field')) {
			let q = questions.find(x=>x.value == watch('field'));
			if (q) {
				setChoices(q.choices);
			}

			if (isDirty) {
				setValue('values', [], { shouldDirty: true })
			}
		}
	}, [watch('field')]);

	useEffect(() => {
		if (!isDirty) {
			return; 
		}
		let vals = watch('values').map((v) => {
			return `'${v}'`;
		}).join(",");

		let cond = `[${vals}].includes(${watch('field')}?.toString())`;

		if (watch('equality') == "!=") {
			cond = `!${cond}`;
		}

		setCondition(cond);
		onChange(cond);

	}, [watch('values'), watch('field'), watch('equality')]);

	return <>
	
	<Box className="sub-title" mb={2}>
		This block will only be included if a preceding answer meets this condition...
	</Box>

	<form onSubmit={handleSubmit((data)=>console.log(data))}>
		
		<Stack direction="row" spacing={1}>
			<Box width={220}>
				<FormControl size="small" fullWidth>
					<InputLabel id="field-label">Question</InputLabel>
					<Controller name="field" control={control} defaultValue=""  render={({field}) => (
												
						<Select labelId="field-label" name="field" {...field} variant="outlined" label="Question" MenuProps={{dense: true, sx:{"& .MuiListSubheader-root": {lineHeight: 2}, "& .MuiMenuItem-root": {paddingLeft: 6, lineHeight: 1}}}}>
							<MenuItem value=""></MenuItem>
							{questions.map((q, i) => {
								if (q.value.startsWith('s-')) {
									return <ListSubheader sx={{pl:2, pt:1, borderBottom: `solid 1px ${theme.palette.disabled.light}`}}>{q.label}</ListSubheader>
								} else if (q.value.startsWith('b-')) {
									return <ListSubheader sx={{pl:4, opacity: 0.65}}>{q.label}</ListSubheader>
								} else {
									return <MenuItem key={i} value={q.value}>{q.label}</MenuItem>
								}
							})}

							
						</Select>
					)}/>
				</FormControl>
			</Box>
			<Box flex={2} width={140}>
				<FormControl size="small" fullWidth disabled={!watch('field')}>
					<InputLabel id="field-label">Is</InputLabel>
					<Controller name="equality" control={control} defaultValue="==" render={({field}) => (
						<Select labelId="field-label" name="equality" {...field}  variant="outlined" label="Is">
							<MenuItem value="==">any of...</MenuItem>
							<MenuItem value="!=">none of...</MenuItem>
						</Select>
					)}/>
				</FormControl>
			</Box>
			<Box flex={4} width={220}>
				<FormControl size="small" fullWidth disabled={!watch('field')}>
					<InputLabel id="field-label">Answer(s)</InputLabel>
					<Controller name="values" control={control} render={({field}) => {
						const renderValues = (selected) => {
							if (selected?.length > 1) {
								return `${selected.length} selected`;
							}
							return selected.map((s, i) => {
								return choices.find(c=>c.code == s)?.label;
							}).join(", ");
						}
						const select = (e,v) =>{
							setValue('values',e.target.value, { shouldDirty: true });
						}
						return <Select labelId="field-label" {...field} multiple variant="outlined" onChange={select} label="Answer(s)" renderValue={renderValues} input={<OutlinedInput label="Answer(s)" />}>
							<MenuItem value="field1"></MenuItem>
							{choices.map((c, i) => {
								return <MenuItem key={i} value={c.code}>
									<Checkbox checked={watch('values').indexOf(c.code) > -1} sx={{padding:0, mr:1}}/>
									<ListItemText primary={c.label} />
								</MenuItem>
								
							})}
						</Select>
					}}/>
				</FormControl>
			</Box>
		</Stack>

		
	</form>

	</>;
}