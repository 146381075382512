
import React, { useRef } from 'react';
import { Avatar, Box, Button, Stack, Link, FormControl, Select, InputLabel, MenuItem, TextField, InputAdornment, IconButton, OutlinedInput, Typography, Tooltip, Menu, Divider, MenuList, ListItem, ListItemIcon, Dialog, DialogActions, FormHelperText, Paper, Badge, useMediaQuery } from '@mui/material';
import UserAvatar from './UserAvatar';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect } from 'react';
import jax from '../helper/jax';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { ArrowDropDown, AssignmentTurnedIn, Block, Cancel, Check, Clear, CommentOutlined, ContentPaste, ContentPasteOff, MoreHoriz, MoreTime, Pending, PendingActions, Star, StarBorderPurple500, Stars, TaskAlt, Upload } from '@mui/icons-material';
import MobileTooltip from './MobileTooptip';
import Icon from './Icon';
import { courseStatusMap } from '../helper/util';
import ProfileDialog from './ProfileDialog';
import { UserRoles } from '../admin/Personnel';
import { ListSubheader } from '@mui/material';
import { BadgeIcon, BadgeMenu } from '../admin/Tracks.tsx';
import Notification from './Notification.js';


// export default function Member(props) {
// 	const {member, courseId, outcomes, canEdit, isClosed, userIsAdmin, onChange, enrolling, courseRoles, isMe} = props;
// 	const [showComments, setShowComments] = React.useState(false);
// 	const [showUserProfile, setShowUserProfile] = React.useState(null);
// 	const [disapproving, setDisapproving] = React.useState(false);
	
// 	const [validRoles, setValidRoles] = React.useState([]);
// 	const {roleMap} = useSelector(state=>state.data);
	
	
// 	const theme = useTheme();

// 	const [anchorEl, setAnchorEl] = React.useState(null);

// 	const setOutcome = async function(outcome_id) {
// 		onChange({outcome_id: outcome_id});
// 	}

// 	const makePrimary = async function() { 
// 		try {
// 			await jax.post(`/app/courses/${member.course_id}/primary_instructor`, {uid: member.uid});

// 			if (props.onMakePrimary) {
// 				props.onMakePrimary(member.uid);
// 			}
// 		} catch (err) {
			
// 		}
// 	}

// 	useEffect(()=>{
// 		var valid = courseRoles.map(r=>{
// 			return roleMap[r.role_id];
// 		});
		
// 		setValidRoles(valid);

// 	},[]);

// 	const approveAs = async function(role_id, reason) {
		
// 		if (role_id) {
// 			var result = await jax.post(`/app/courses/${member.course_id}/approve`, {uid: member.uid, role_id: role_id});
// 			onChange({...result, approved: true, comments: null, course_role_id: role_id}, true);
// 		} else {
// 			if (disapproving && reason) {
// 				var result = await jax.post(`/app/courses/${member.course_id}/disapprove`, {uid: member.uid, comments: reason});
// 				onChange({...result, approved: false}, true);
// 			} else {
// 				setDisapproving(true);
// 			}
// 		}
// 	}

// 	if (!canEdit && !member?.approved && !isMe) {
// 		return <></>;
// 	}

// 	return <Box p={2} py={3} flex={1} typography="body1" bgcolor={props.index % 2 == 1 ? '#f6f6f6' : ''} sx={{color: canEdit &&  !member.approved ? '#999' :''}}>
// 		<Stack direction={{xs:"column", lg:"row"}} justifyContent="space-between" alignItems="flex-start" spacing={2}>
// 			<Stack direction="row" spacing={2} flex={1} alignItems="flex-start">
// 				<UserAvatar user={member} sx={{ bgcolor: '#999', width: 32, height: 32 }}/>
// 				<Stack sx={{flex:1}} >
// 					<Stack  direction="row" alignItems="center" spacing={0.5} sx={{lineHeight: 1}}><div ><Link onClick={()=>setShowUserProfile(member.uid)}>{member.title}</Link></div>{userIsAdmin && !!member.evaluated ? <MobileTooltip placement="top" disableFocusListener title="Evaluation Submitted"> <Box color={theme.palette.primary.main} ><AssignmentTurnedIn fontSize="small" ></AssignmentTurnedIn></Box></MobileTooltip> : userIsAdmin && <MobileTooltip placement="top" disableFocusListener title="Evaluation Pending"><Box color="#999999" ><ContentPasteOff fontSize="small"></ContentPasteOff></Box></MobileTooltip>}</Stack>
// 					<Box px={{xs:2,md:0}}>
// 						<UserRoles user={member} size="small"/>
// 					</Box>
// 				</Stack>
				
// 				{isClosed && canEdit ? <Stack alignItems="flex-end" spacing={0.5}>
// 					{isClosed && userIsAdmin && member?.course_role_id != 4 && <Stack direction="row" alignItems="center">
// 						{/* <Icon name={courseStatusMap[gradStatus]?.icon} fontSize="small"  sx={{mr:0.5, color:courseStatusMap[gradStatus]?.color}}></Icon> <Typography lineHeight={1} color={courseStatusMap[gradStatus]?.color}>{courseStatusMap[gradStatus]?.label}</Typography> */}
						
// 						{/* {member.graduated && <>{member.distinction ? <Stars fontSize="small" sx={{mr:0.5}}></Stars> : <TaskAlt fontSize="small" sx={{mr:0.5}}></TaskAlt>} <b>Graduated</b></>}
// 						{member.distinction && <><b>&nbsp;w. Distinction</b></>}
// 						{!member.graduated && !member.withdrew && <><MoreTime fontSize="small" sx={{mr:0.5}}></MoreTime> <i>Add'l Training Required</i></>}
// 						{!member.graduated && member.withdrew && <><Block fontSize="small" color='error' sx={{mr:0.5}}></Block> <Typography lineHeight={1} color="error">Disenrolled</Typography></>} */}
						
// 					</Stack>}
// 				</Stack> : <></>}
// 			</Stack>

// 			{/* For non-primary instructors, show a button to make them primary */}
// 			{!!canEdit && (!!member.instructor && !member.primary) && !isClosed ? <Box alignSelf="flex-end" textAlign={{lg:"right", xs:"center"}} pt={{xs:1, lg:0}}>
// 				<Button size="small" variant="outlined" color="primary" sx={{mt:0, lineHeight: 0}} startIcon={<StarBorderPurple500/>} onClick={makePrimary}>Make Lead</Button>
// 			</Box> : <></>}

// 			{!!canEdit && (!!member.instructor && member.primary) && !isClosed ? <Stack direction="row" alignSelf="flex-end" alignItems="center" justifyContent="center" lineHeight={0} spacing={0.5} textAlign={{lg:"right", xs:"center"}} pt={{xs:1, lg:0}}>
// 				<Box><Stars/></Box>
// 				<Box>Lead Instructor</Box>
// 			</Stack> : <></>}

// 			{!!canEdit && !!enrolling && <Box alignSelf="flex-end" >
// 				<Button sx={{lineHeight: 0}} size="small" startIcon={member.approved ? <Check/> : <Block/>} variant="contained" color={member.approved ? "primary" : "error"} onClick={(e)=>setAnchorEl(e.currentTarget)} endIcon={<ArrowDropDown sx={{lineHeight: 0}} />}>{member.approved ? "Approved" : "Disapproved"}</Button>
// 				<Menu

// 					id="simple-menu"
// 					anchorEl={anchorEl}
// 					dense
// 					transformOrigin={{vertical: 'top', horizontal: 'right'}}
// 					anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
// 					open={Boolean(anchorEl)}
// 					slotProps={{paper: {sx:{pb: 1}}}}
// 					onClose={()=>setAnchorEl(null)}>
// 						<MenuList dense >
							
// 						{validRoles.length == 1 && <MenuItem onClick={()=>{setAnchorEl(null);approveAs(member.course_role_id)}}>Approve</MenuItem>}
// 						{validRoles.length > 1 && <Divider></Divider>}
// 						<ListSubheader>Approve as:</ListSubheader>
// 						{validRoles.length > 1 && validRoles.map((r,i)=><MenuItem  key={i} onClick={()=>{setAnchorEl(null);approveAs(r.id)}}>
// 							<ListItemIcon>
// 								{r.id == member.course_role_id && !!member.approved && <Check /> }
// 							</ListItemIcon>
// 								{r.name}
// 						</MenuItem>)}
// 						<Divider></Divider>
// 						<MenuItem onClick={()=>{setAnchorEl(null);approveAs(null)}}>Disapprove Enrollment</MenuItem>
// 					</MenuList>
// 				</Menu>
// 			</Box>}

// 			{!!canEdit && !member.instructor && !isClosed && !enrolling ? <Box alignSelf="flex-end">
// 					{!!outcomes.length && !!member.approved && <FormControl fullWidth sx={{  width:{lg:220}, mt: {xs:3, lg:0} }} size="small" required >
// 						<InputLabel id="enroll-label">Outcome</InputLabel>			
// 						<Select size="small" labelId="enroll-label" label="Outcome" value={member.outcome_id === null ? '' : member.outcome_id} required onChange={(e)=>setOutcome(e.target.value)}>
// 							<MenuItem value="">&nbsp;</MenuItem>
// 							{/* <MenuItem value="1">Graduate</MenuItem>
// 							{member.course_role_id == 1 && <MenuItem value="2">Graduate w/ Distinction</MenuItem>}
// 							<MenuItem value="0">Add'l Training Required</MenuItem>
// 							<MenuItem value="-1">Disenrolled</MenuItem> */}
// 							{outcomes?.map((o,i)=><MenuItem key={i} value={o.id}>{o.name}</MenuItem>)}
// 						</Select>
// 					</FormControl>}

					
// 				</Box> : <></>
// 			}

// 			{(!canEdit || isClosed) && <div >{member.primary && canEdit ? 'Lead ' : ''}{member.instructor ? 'Instructor' : member.course_role}</div> }

			
// 		</Stack>
// 		<Paper variant="outlined">
// 			<Stack>
// 				<Stack flex={1} direction="row" alignItems="stretch">
// 					<Button size="small" onClick={()=>setShowUserProfile(member.uid)}>Profile</Button>
// 					<Divider flexItem orientation='vertical'></Divider>
// 					<Button size="small" onClick={()=>setShowUserProfile(member.uid)} startIcon={<CommentOutlined/>}> Comments</Button>
// 				</Stack>
// 				<Divider/>
// 				<Box className="sub-title" sx={{fontStyle:"italic", color:"#666666", padding:1}} >
// 					&quot;Mr. Wier was an excellent student.  Would teach again...&quot;
// 				</Box>
// 			</Stack>
// 		</Paper>
// 		{!isClosed && canEdit && !member.instructor && (member.comments || showComments) ? <Box sx={{mt:2}}>
// 			<FormControl fullWidth sx={{  mt: {xs:0, lg:0} }} size="small"  >
// 				<InputLabel htmlFor="comments">Comments</InputLabel>
// 				<OutlinedInput fullWidth error={(!member.comments || !member.comments.trim())} label="Comments" size="small" value={member.comments} onChange={(e)=>onChange({comments:e.target.value})}
// 					endAdornment={<InputAdornment position="end">
// 							<IconButton onClick={()=>{setShowComments(false); onChange({comments:null})}} size="small" color="primary" aria-label="toggle comments" edge="end"><Clear></Clear></IconButton>
// 						</InputAdornment>} ></OutlinedInput>
// 				<FormHelperText sx={{textAlign:"right"}}>Visible only to Program Managers and Administrators</FormHelperText>
// 			</FormControl>
// 		</Box> : <></>}

// 		<ProfileDialog uid={showUserProfile} onClose={()=>setShowUserProfile(null)}>
// 		</ProfileDialog>

// 		{!isClosed && canEdit && !member.instructor && (!showComments) && (!enrolling || !member.approved) && !member.comments ? <Box sx={{mt:0}} textAlign="right">
// 			<Button size="small"   onClick={()=>setShowComments(true)}>+ Add Comments</Button>
// 		</Box> : <></>}
// 		{/* <DisapproveDialog open={disapproving} onClose={()=>setDisapproving(false)} onSave={(r)=>approveAs(null,r)}></DisapproveDialog> */}
// 	</Box>;
// }

export function MemberV2(props) {
	const {member, courseId, outcomes, badgeIds, canEdit, isClosed, userIsAdmin, userIsLead, onChange, isEnrolling, isEvalWindow, courseRoles, isMe} = props;
	const [showComments, setShowComments] = React.useState(false);
	const [showUserProfile, setShowUserProfile] = React.useState(null);
	const [disapproving, setDisapproving] = React.useState(false);
	const [addingComments, setAddingComments] = React.useState(false);
	const [validRoles, setValidRoles] = React.useState([]);
	const {roleMap} = useSelector(state=>state.data);
	const [options, setOptions] = React.useState({});
	const {terms} = useSelector(state=>state.app);
	const theme = useTheme();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [addBadgeEl, setAddBadgeEl] = React.useState(null);
	const is_mobile = useMediaQuery(theme => theme.breakpoints.down('md'));
	const {badges} = useSelector(state=>state.data);
	const [showBadge, setShowBadge] = React.useState(null);

	const setOutcome = async function(outcome_id) {
		onChange({outcome_id: outcome_id});
	}

	const makePrimary = async function() { 
		try {
			await jax.post(`/app/courses/${member.course_id}/primary_instructor`, {uid: member.uid});

			if (props.onMakePrimary) {
				props.onMakePrimary(member.uid);
			}
		} catch (err) {
			
		}
	}

	useEffect(()=>{
		var valid = courseRoles.map(r=>{
			return r; //roleMap[r.role_id];
		});
		
		setValidRoles(valid);

		setOptions({
			APPROVAL: isEnrolling && (userIsAdmin || userIsLead),
			APPROVE: isEnrolling && userIsAdmin,
			COMMENTS: userIsAdmin || userIsLead,
			EDITCOMMENTS: (!isEnrolling && !isClosed && !isMe && (userIsAdmin || userIsLead)) || (isEnrolling && userIsAdmin && member.comments),
			MAKELEAD: userIsAdmin && !!member.instructor && !member.primary && !isClosed,
			TRACKS: userIsAdmin,
			EDITOUTCOME: !isClosed && !isEnrolling && (userIsAdmin || userIsLead),
			OUTCOME: isClosed && (userIsAdmin || userIsLead || isMe),
			EVALSTATUS: !isEnrolling && (isEvalWindow || isClosed) && (userIsAdmin || userIsLead),
			BADGES: userIsAdmin,
			ADDBADGES: (userIsAdmin || userIsLead) && badgeIds?.length && !isEnrolling && !isClosed
		});
	},[userIsAdmin, member, isEnrolling, isClosed, isEvalWindow, isMe]);

	const approveAs = async function(role_id, reason) {
		
		if (role_id) {
			var result = await jax.post(`/app/courses/${member.course_id}/approve`, {uid: member.uid, role_id: role_id});
			onChange({...result, approved: true, comments: null, course_role_id: role_id}, true);
		} else {
			if (disapproving && reason) {
				var result = await jax.post(`/app/courses/${member.course_id}/disapprove`, {uid: member.uid, comments: reason});
				onChange({...result, approved: 0}, true);
			} else {
				setDisapproving(true);
			}
		}
	}

	const addComments = async function(comments) {
		onChange({comments: comments});
	}

	const addBadge = async function(badge_id) {
		var badges = member.badges || [];
		if (!badges.includes(badge_id)) {
			if (isClosed) {
				await jax.post(`/app/courses/${courseId}/badges`, {uid: member.uid, badge_id});
			}
			badges.push(badge_id);
			onChange({badges: badges});
		}
	}

	const removeBadge = async function(badge_id) {
		var badges = member.badges || [];
		if (badges.includes(badge_id)) {
			badges = badges.filter(b=>b != badge_id);
			onChange({badges: badges});
		}
	}

	if (!canEdit && !member?.approved && !isMe) {
		return <></>;
	}

	return <Box px={{md:3, xs:1}} my={{md:2, xs:1}}>
		
			<Paper variant="outlined" sx={{p:0, mt:{xs:2, md:3}, position:'relative'}}>
				
				<Stack direction="row" spacing={1} flex={1}  alignItems="flex-end" sx={{position:"absolute", top:0,right:"16px", transform:"translate(0,-50%)"}}>
					
					{!!member.instructor && !!member.primary && <Stack direction="row" alignItems="center" px={1} py={0.5} spacing={0.5} bgcolor={theme.palette["info"].main} sx={{color:"#ffffff", borderRadius:2, fontSize:"0.9rem"}}>
						<Box lineHeight={0}><Star sx={{mr:0.5, fontSize:"0.85rem"}}/></Box>
						<Box lineHeight={0}>Lead Instructor</Box>
					</Stack>}
					{!!options.BADGES && member?.badges?.length > 0 && <Stack direction="row" spacing={.5} justifyContent="flex-start">
						{member.badges.map((b,i)=>{
							var bb = badges.find(x=>x.id == b);
							
							return bb && <BadgeIcon key={i}  color={bb.color} icon={bb.icon} size="small" tooltip={bb.name} onClick={(e)=>setShowBadge({...bb, el:e.currentTarget})} />
							
							
						})}

						<Notification open={!!showBadge} right={true} anchorEl={showBadge?.el} onClose={()=>setShowBadge(null)} ContainerProps={{maxWidth:'280px'}}>
							{showBadge && <Stack spacing={1}>
								<Stack direction="row" alignItems="flex-start" spacing={1}>
									<Box><BadgeIcon size="small" icon={showBadge.icon} color={showBadge.color}></BadgeIcon></Box>
									<Stack spacing={1}>
										<Box>{showBadge.name}</Box>
										<Box className="sub-title xs">{showBadge.description}</Box>
										<Box>
											<Button variant="text" color="error" size="small" onClick={()=>{setShowBadge(null);removeBadge(showBadge.id)}}>Remove {terms["BADGE"].s}</Button>
										</Box>
									</Stack>
								</Stack>
							</Stack>}
						</Notification>
					</Stack>}
				</Stack>

				
				<Stack direction="row" spacing={2} flex={1} p={2} pb={1} alignItems="flex-start">
					<UserAvatar user={member} sx={{ bgcolor: '#999', width: 32, height: 32 }}/>
					<Stack direction={{xs:"column", md:"row"}} flex={1} alignItems={{xs:"stretch", md:"flex-start"}}>
						<Stack direction="column" sx={{flex:1}} >
							<Stack  direction="row" flex={1} alignItems="center" spacing={0.5} sx={{lineHeight: 1}}><div ><Link onClick={()=>setShowUserProfile(member.uid)}>{member.title}</Link></div>
								{!!options.EVALSTATUS && !!(isEvalWindow || isClosed) && <>
									{!!member.evaluated ? <MobileTooltip placement="top" disableFocusListener title="Evaluation Submitted"> <Box color={theme.palette.primary.main} ><AssignmentTurnedIn fontSize="small" ></AssignmentTurnedIn></Box></MobileTooltip> : userIsAdmin && <MobileTooltip placement="top" disableFocusListener title="Evaluation Pending"><Box color="#999999" ><ContentPasteOff fontSize="small"></ContentPasteOff></Box></MobileTooltip>}
								</>}
							</Stack>
							<Box px={{md:0}}>
								<UserRoles user={member} size="small"/>
							</Box>
							
						</Stack>
						<Stack  pt={1} spacing={0} alignItems="flex-end">
							{!!options.EDITOUTCOME && !!outcomes.length && !!member.approved && <Box flex={1} width="100%">
								<FormControl fullWidth sx={{  width:{lg:220}, my: {xs:1, lg:0} }} size="small" required >
									<InputLabel id="enroll-label">Outcome</InputLabel>			
									<Select size="small" labelId="enroll-label" label="Outcome" value={member.outcome_id === null ? '' : member.outcome_id} required onChange={(e)=>setOutcome(e.target.value)}>
										<MenuItem value="">&nbsp;</MenuItem>
										{outcomes?.map((o,i)=><MenuItem key={i} value={o.id}>{o.name}</MenuItem>)}
									</Select>
								</FormControl>
							</Box>}

							{!!options.OUTCOME && !!outcomes.length && member.outcome_id && <Box flex={1} width="100%">
								{outcomes.find(o=>o.id == member.outcome_id)?.name}
							</Box>}
							

							
						</Stack>
					</Stack>
					
					
				</Stack>

				<Divider sx={{opacity: 0.5}}/>

				<Stack direction={{xs:"column", lg:"row"}}>
					{!!options.APPROVAL && <>
						<Button disableElevation sx={{px:2}} size="small" startIcon={member.approved ? <Check/> : member.approved === 0 ? <Block/> : <MoreHoriz/>} variant="text" color={member.approved ? "primary" : member.approved === 0 ? "error" : "warning"} onClick={options.APPROVE ? (e)=>setAnchorEl(e.currentTarget) : undefined} endIcon={options.APPROVE && <ArrowDropDown sx={{lineHeight: 0}} />}>{member.approved ? "Enrollment Approved" : member.approved === 0 ? "Enrollment Disapproved" : "Approval Required"}</Button>
						<Menu
		
							id="simple-menu"
							anchorEl={anchorEl}
							dense
							transformOrigin={{vertical: 'top', horizontal: 'right'}}
							anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
							open={Boolean(anchorEl)}
							slotProps={{paper: {sx:{pb: 1}}}}
							onClose={()=>setAnchorEl(null)}>
								<MenuList dense >
									
								{validRoles.length == 1 && <MenuItem onClick={()=>{setAnchorEl(null);approveAs(member.course_role_id)}}>Approve</MenuItem>}
								{validRoles.length > 1 && <Divider></Divider>}
								<ListSubheader>Approve as:</ListSubheader>
								{validRoles.length > 1 && validRoles.map((r,i)=><MenuItem  key={i} onClick={()=>{setAnchorEl(null);approveAs(r.id)}}>
									<ListItemIcon>
										{r.id == member.course_role_id && !!member.approved && <Check /> }
									</ListItemIcon>
										{r.name}
								</MenuItem>)}
								<Divider></Divider>
								<MenuItem onClick={()=>{setAnchorEl(null);approveAs(null)}}>Disapprove Enrollment</MenuItem>
							</MenuList>
						</Menu>
						<Divider orientation="vertical" sx={{opacity: 0.5}} flexItem/>
						{is_mobile && <Divider orientation="horizontal" sx={{opacity: 0.5}} flexItem/>}
						</>
					} 
					
					<Box flex={1}></Box>

					{!!options.ADDBADGES && <>
						<Divider orientation="vertical" sx={{opacity: 0.5}} flexItem/>
						{is_mobile && <Divider orientation="horizontal" sx={{opacity: 0.5}} flexItem/>}
						<Button size="small" variant="text" color="primary" sx={{px:2}} startIcon={<Stars/>} onClick={(e)=>setAddBadgeEl(e.currentTarget)} >Complete {terms["BADGE"].s}(s)</Button>						
					</>}
					{!!options.MAKELEAD && <>
						
						<Divider orientation="vertical" sx={{opacity: 0.5}} flexItem/>
						{is_mobile && <Divider orientation="horizontal" sx={{opacity: 0.5}} flexItem/>}
						<Button size="small" variant="text" color="primary" sx={{px:2}} startIcon={<StarBorderPurple500/>} onClick={makePrimary}>Make Lead</Button>
					</>}
					{!!options.EDITCOMMENTS && <>
						<Divider orientation="vertical" sx={{opacity: 0.5}} flexItem/>
						{is_mobile && <Divider orientation="horizontal" sx={{opacity: 0.5}} flexItem/>}
						<Button size="small" onClick={()=>setAddingComments(true)} startIcon={<CommentOutlined/>} sx={{px:2}}> {member.comments ? "Edit" : "Add"} Comments</Button>
					</>}
				</Stack>
				{!!options.COMMENTS && member.comments && <>
					<Divider sx={{opacity: 0.5}} />
					{is_mobile && <Divider orientation="horizontal" sx={{opacity: 0.5}} flexItem/>}
					<Box className="sub-title" sx={{color:"#666666", p:1, px:2}} >
						<b>Comments:</b> <span style={{fontStyle:"italic"}}> "{member.comments || "No comments provided"}"</span>
					</Box>
				</>}
				<Box flex={1}></Box>
			</Paper>
			
			<CommentsDialog open={disapproving} onClose={()=>setDisapproving(false)} onSave={(r)=>approveAs(null,r)} color="error" comments={member.comments} buttonText="Disapprove Enrollment" title={"Disapprove Enrollment"} instructions="Please provide a reason for disapproval.  These comments will be provided to the disapproved participant.  Your contact email address will be provided to the participant in case they have questions."></CommentsDialog>
			<CommentsDialog open={addingComments} onClose={()=>setAddingComments(null)} onSave={addComments} color="success" comments={member.comments} buttonText="Save Comments" title={"Add Comments"} instructions={`Please add your comments regarding ${member.title} below.  Your comments will be visible to the Lead Instructor, Program Managers and Administrators.`}></CommentsDialog>
			<ProfileDialog uid={showUserProfile} onClose={()=>setShowUserProfile(null)}></ProfileDialog>
			<BadgeMenu badgeIds={badgeIds} el={addBadgeEl} onSelect={addBadge} onClose={()=>setAddBadgeEl(null)}></BadgeMenu>
		
		</Box>;
}

const CommentsDialog = (props) => {
	const {open, color, title, comments, instructions, buttonText, onClose, onSave} = props;
	
	const [loading, setLoading] = React.useState(false);
	const [reason, setReason] = React.useState(comments);

	const save = async function() {
		setLoading(true);
		await onSave(reason);
		setLoading(false);
		onClose();
	}

	return <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
		<Stack p={2} spacing={2} >
			<Box>
				<Typography variant="h6">{title}</Typography>
			</Box>
			<Box>
				{instructions}
			</Box>
			<Box pt={1}>
				<TextField fullWidth  size="small" label="Comments" variant="outlined" required multiline rows={3} value={reason} onChange={(e)=>setReason(e.currentTarget.value)}></TextField>
			</Box>
		</Stack>
		<DialogActions>
			<Button size="small" onClick={onClose}>Cancel</Button>
			<Button size="small" onClick={save} variant="contained" disabled={loading} color={color || "success"}>{buttonText}</Button>
		</DialogActions>
	</Dialog>
} 

