import { useEffect } from "react";
import { useParams } from "react-router-dom"
import jax from "../helper/jax";
import { Backdrop, Avatar, Card, CircularProgress, Stack, Divider, Box, OutlinedInput, CardContent, useMediaQuery, Typography, Button, Fab, Popover, Alert, Breadcrumbs, Link } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";	
import CourseList from "../components/CourseList";
import { Controller, useForm } from "react-hook-form";
import { TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import Icons from "../icons";
import { Camera, CameraAlt, Photo } from "@mui/icons-material";
import { useRef } from "react";
import { IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";
import UnitSearch from "../components/UnitSearch";
import algoliasearch from 'algoliasearch';
import { setUser, updateLocalUser } from "../features/data";
import {useDispatch} from 'react-redux';
import { CallEnum } from '@algolia/transporter';
import { Link as RouterLink } from "react-router-dom";

import FeedbackButton from "../components/FeedbackButton";
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import app from '../helper/firebase';
import TrainingHistory from "../components/TrainingHistory";
import UnitSelector from "../components/UnitSelector";
import AddTraining from "../components/AddTraining";
import { hasFeature } from "../store";
import { features } from "../features/access";

export default function Profile() {
	const prms = useParams();
	const user = useSelector((state) => state.data.user);
	const uid = prms.uid || user.uid;
	const [profile, setProfile] = useState(null);
	const [training, setTraining] = useState(null);
	const [editing, setEditing] = useState(false);
	const affiliations = useSelector((state) => state.data.affiliations);
	const agencies = useSelector((state) => state.data.agencies);
	const ranks = useSelector((state) => state.data.ranks);
	const [photo, setPhoto] = useState(null);
	const [photoUpload, setPhotoUpload] = useState(null);
	const [unit, setUnit] = useState(null);
	const [unitLogo, setUnitLogo] = useState(null);
	const [saving, setSaving] = useState(false);
	const [saveError, setSaveError] = useState(null);
	const [addTraining, setAddTraining] = useState(false);
	const {config} = useSelector((state) => state.data);
	
	var dispatch = useDispatch();

	var isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
	
	const {control , handleSubmit, setValue, reset, watch, formState: { errors }} = useForm({
		first_name: '',
		last_name: '',
		phone: '',
		photo: '',
		mil_id: '',
		affiliation: '',
		agency: '',
		rank: '',
		unit: '',
		email: '',
		other_unit: ''
	});

	const resetFields = function(data) {
		reset({
			first_name: data.first_name,
			last_name: data.last_name,
			phone: data.phone,
			photo: data.photo,
			mil_id: data.mil_id,
			affiliation_id: data.affiliation_id,
			agency_id: data.agency_id,
			rank_id: data.rank_id,
			unit: data.unit,
			email: data.email,
			other_unit: data.other_unit
		});

		if (data.unit_id) {
			//We need the full algolia client here to use getObject (not the "lite" version)
			const client = algoliasearch('P3M95K0JOU', 'f5ddf86fede1573ebf331eb58b6879c3', {
				hosts: [{ protocol: window.location.protocol.replace(':',''), url: `${window.location.host}/app/search`, accept: CallEnum.Read}]
			});
			const search_index = client.initIndex(config.unit_index);
			search_index.getObject(data.unit_id).then((unit)=>{
				setUnit(unit);
			});
		}
	}

	const updateProfile = async function(data) {		
		try {
			data.unit_id = unit?.id;
			setSaving(true);
			setSaveError(null);
			var result = await jax.post('/app/profile/'+uid, data);
			setEditing(false);
			setProfile({...profile, ...result.profile});
			dispatch(setUser({...user, ...result.profile}));
			resetFields(data);

			//If the user's email address was changed, they may need to re-verify their email address.  Make sure client claims are up to date with a new token
			if (result.new_token) {
				var auth = getAuth(app);
				var cred = await signInWithCustomToken(auth, result.new_token);
			}
		} catch (error) {
			if (error.code == 409) {
				setSaveError('A user with this email address already exists.');
			} else if (error.code == 400) {
				setSaveError(error.message); //generally a mil id conflict
			}
			console.log(error);
		} finally {
			setSaving(false);
		}
	}

	useEffect(()=>{
		if (!editing) {
			setSaveError(null);
			setSaving(false);
		}
	}, [editing]);

	useEffect(()=>{
		jax.get('/app/profile/'+uid).then((res)=>{;
			setProfile(res.profile);
			setTraining(res.training);
			resetFields(res.profile);

			
		});
	}, []);

	useEffect(()=>{
		var abbr = agencies.find((x)=>x.id == unit?.agency_id)?.abbr;
		if (abbr) {
			setUnitLogo(`/images/agency/${abbr}.png`);
		}
	}, [unit]);


	const handleFile = function(e) {
		const fileUploaded = e.target.files[0];
		setPhotoUpload(fileUploaded);
		if (fileUploaded) {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(e.target.files[0]);
			fileReader.addEventListener("load", function () {
				setPhoto(this.result);
				reset({new_photo: this.result.substr(22)});
			});
		}
	}

	function updateTraining(t,updated_profile) {
		setTraining(t);
		dispatch(updateLocalUser({last_refresh: updated_profile.last_refresh}));
		setProfile({...profile, ...updated_profile});
		let event = new CustomEvent("load_alerts");
		window.dispatchEvent(event);
	}

	const hiddenFileInput= useRef(null);

	return profile ? <Stack spacing={4}>
		
		<AddTraining open={addTraining} user={profile} onClose={()=>setAddTraining(false)} onSubmit={(d,t)=>setTraining(t)}></AddTraining>
			<Breadcrumbs>
				<RouterLink to="/">Home</RouterLink>
				<Typography>My Profile</Typography>
			</Breadcrumbs>
		<form onSubmit={handleSubmit(updateProfile)}><Stack direction={{xs:"column", lg:"row"}} spacing={2}>
		
		<Stack direction="column" spacing={2} flex={1} >
			
			<div style={{marginLeft: isMobile?'25%': 0, marginRight: isMobile?'25%': 0, lg: 0}}>
				<div style={{paddingTop:"100%", position:"relative"}}>
					{photo ? 
						<Box sx={{backgroundImage: `url(${photo})`, backgroundSize:'cover', borderRadius: 1000, position:'absolute', width:'80%', height:'80%', top: '50%', left:'50%', transform: 'translate(-50%, -50%)'}}/> 
						: <Avatar src={profile.photo} variant="rounded" sx={{backgroundColor: '#888888', opacity: editing && !photo ? 0.25 : 1, position:'absolute', width:'80%', height:'80%', top: '50%', left:'50%', transform: 'translate(-50%, -50%)' }}/>
					}
					{editing && <Box sx={{position:'absolute', bottom: '0%', left:'50%', transform: 'translateX(-50%)' }} >
						<Fab size="large" color="primary" onClick={()=>{hiddenFileInput.current.click()}}>
							<CameraAlt/>
						</Fab>
						<input type="file" accept="image/*" ref={hiddenFileInput} style={{display: 'none'}} onChange={handleFile}/>
					</Box>}
				</div>
				
			</div>
			
		</Stack>
		<Stack direction="column" spacing={4} flex={5}>
			<Box>
				<Stack direction={{xs:"column", md:"row"}} spacing={2}  justifyContent="space-between" alignItems={{xs:"stretch", lg: "center"}}>
					<Box flex={1}>
						<Stack mb={{xs:3,lg:1}} direction={{xs:"column", lg:"row"}} spacing={2} alignItems="center" justifyContent="space-between">
							<Typography variant="h4" sx={{margin:"0 0 16px 0"}} textAlign={{xs:"center", lg:"left"}}>{profile.name}</Typography>
							{uid == user.uid && !editing && <Button variant="outlined" onClick={()=>setEditing(true)}>Edit My Profile</Button>}
						</Stack>
						<Stack direction={{xs:"column", lg:"row"}} spacing={{xs:1,lg:3}}>
							{profile.rank && <Box>
								<h4 style={{fontWeight: 'normal', margin:"0"}}><img style={{height: '32px', width: '32px', verticalAlign: 'middle', display:'inline', marginRight:8}} src={`/images/agency/${profile.agency_abbr}.png`}/>{profile.rank} | {profile.affiliation}  {profile.agency} </h4>
							</Box>}
							{profile.role && <Box>
								<h4 style={{fontWeight: 'normal', margin:"0"}}><img style={{height: '32px', width: '32px', verticalAlign: 'middle', display:'inline', marginRight:4}} src={`/images/logo.png`}/> Valkyrie {profile.role}</h4>
							</Box>}
						</Stack>
					</Box>
				</Stack>
			</Box>
			
			{!editing ? <Box mt={4}>
				<Card sx={{p:0}}>
					<Stack direction="row" className="card-header" px={1} alignItems="center" justifyContent="space-between">
						<Box>Training History</Box>
						<Button  variant="outlined" color="white" size="small" onClick={()=>setAddTraining(true)}>Add Training History</Button>
					</Stack>
					<Box p={2} className="sub-title">
						If you don't see your full training history reflected, you can <Link variant="text" color="primary" onClick={()=>setAddTraining(true)}>submit evidence of prior training</Link> to be reviewed and verified. 
					</Box>
					<Divider/>
					{training && training.length ? <TrainingHistory records={training} user_id={profile.uid} onTrainingUpdate={updateTraining} onSubmitTraining={()=>setAddTraining(true)}></TrainingHistory> : <Box p={4} textAlign="center">No training history on record...</Box>}
					
				</Card>
			</Box> : <Box>
				<Card>
					<Box className="card-header">Update Your Profile</Box>
					<Stack direction={{lg:"row", xs:'column'}} justifyContent="space-around" >
						<Box sx={{flex:1, p:4}} maxWidth="sm">
							<Stack  spacing={4} alignItems="stretch" sx={{flex:1}} >
								<Box sx={{flex:1}}>
									<Box className="section-header">Your Information</Box>
									<Box pt={2}>
										
										<Stack spacing={2}>
											<Controller name="first_name" defaultValue='' control={control} rules={{required: 'Required'}}  render={({ field }) => <TextField label="First Name" helperText={errors.first_name?.message} error={!!errors.firstName} size="small" {...field} />}/>
											<Controller name="last_name" defaultValue='' control={control} rules={{required: 'Required'}} render={({ field }) => <TextField label="Last Name" helperText={errors.last_name?.message} error={!!errors.last_name} size="small" {...field} />}/>
											<Controller name="phone" defaultValue='' control={control}  render={({ field }) => <TextField label="Phone" helperText={errors.phone?.message} error={!!errors.phone} size="small" sx={{width: "75%"}} {...field} />}/>
											<Controller name="email" defaultValue='' rules={{required: 'Required', pattern: {value: /^\S+@\S+$/i, message: 'Enter a valid email'}}} control={control}  render={({ field }) => <TextField label="Email" size="small" helperText={errors.email?.message || "Changing your email will also change the email you use to login" } error={!!errors.email} {...field} />}/>
										</Stack>
									</Box>
								</Box>							
							</Stack>
						</Box>
						{hasFeature(features.MILITARY_PROFILES) && <>
							<Divider variant="middle" orientation={isMobile ? "horizontal" : "vertical"} flexItem sx={{}} ></Divider>
							<Box sx={{flex:1, p:4}}>
								<Box className="section-header">Military Profile</Box>
								<Box py={1	}>
									
									<Stack spacing={2}>
										<Box>Military personnel, please complete as appropriate.</Box>
										<Controller name="mil_id" defaultValue='' control={control} rules={{required:'Required'}} render={({ field }) =>
											<TextField sx={{ width:"75%" }} label="DODID / EDIPI"  size="small" {...field} helperText={errors.mil_id?.message} error={!!errors.mil_id}></TextField>
										}/>
										<FormControl size="small"  >
											<InputLabel id="affiliation-label">Affiliation</InputLabel>
											<Controller name="affiliation_id" control={control} defaultValue="" render={({ field }) => <Select
												labelId="affiliation-label"
												id="affiliation"
												label="Affiliation"
												{...field}
												>
												<MenuItem key={-1} value="">None</MenuItem>
												{affiliations.map((affiliation) => <MenuItem key={affiliation.id} value={affiliation.id}>{affiliation.name}</MenuItem>)}
											</Select>
											} />
										</FormControl>

										<FormControl size="small"  >
											<InputLabel id="agency-label" >Agency</InputLabel>
											<Controller name="agency_id" control={control} defaultValue="" render={({ field }) => <Select
												{...field}
												labelId="agency-label"
												id="agency"
												disabled={watch('affiliation_id') == null}
												label="Agency"
												
											>
												<MenuItem key={-1} value="">None</MenuItem>
												{agencies.filter((v) => v.affiliation_id == watch('affiliation_id')).map((agency) => <MenuItem key={agency.id} value={agency.id}>{agency.name}</MenuItem>)}

											</Select>
											} />
										</FormControl>

										<FormControl size="small"  >
											<InputLabel id="rank-label" >Rank</InputLabel>
											<Controller name="rank_id" control={control} defaultValue="" render={({ field }) => <Select
												{...field}
												labelId="rank-label"
												id="rank"
												
												disabled={!watch('agency_id')}
												label="Rank"
											>	
												<MenuItem key={-1} value="">None</MenuItem>
												{ranks.filter((v) => v.agency_id == watch('agency_id')).map((rank) => <MenuItem key={rank.id} value={rank.id}>{rank.name}</MenuItem>)}
											</Select>
											} />
										</FormControl>
										
										<UnitSelector label="Assigned Command" unit={unit} onSelect={setUnit} defaultText={profile.other_unit} onTextChange={(t)=>setValue("other_unit", t)} config={config} />
										
									</Stack>
								</Box>
							</Box>
						</>}
					</Stack>
					<Box textAlign="center" p={2}>
						<FeedbackButton variant="contained" loading={saving} error={saveError} severity="error" type="submit">Save Changes</FeedbackButton>
						
						<Button variant="outlined" sx={{ml:2}} onClick={()=>{resetFields(profile); setPhoto(null); setEditing(false);}}>Cancel</Button>
					</Box>
				</Card>
			</Box>}
		</Stack>

		
	</Stack></form></Stack> : <Backdrop open={true}>
		<CircularProgress color="white" />	
	</Backdrop>;
}