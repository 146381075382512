import cookies from 'js-cookie';
import {getAuth} from 'firebase/auth';
import app from './firebase';

const getConfig = async function(config = {}){
	var token = null;
	var provider = null;
	var auth = getAuth(app);
	if (auth && auth.currentUser) {
		token = await auth.currentUser.getIdToken();
		provider = auth.currentUser.providerId;
	}

	config.headers = config.headers || {};
	config.headers['Accept'] = 'application/json';
	config.headers['Content-Type'] = 'application/json';    
	if (token) {
		config.headers['x-id-token'] = token;    
		config.headers['x-id-provider'] = provider;
	}
	var csrf = cookies.get('csrf');
	config.headers["xsrf-token"] = csrf;
	
	config.credentials = 'same-origin';

	if (!config.no_feedback) {
		let event = new CustomEvent("loading_start");
		window.dispatchEvent(event);
	}
	return config;
}

const issue_request = async function(method, url, data, config = { dont_catch: false }) {
	
	return await new Promise((res,rej) => {
		getConfig(config).then((config)=>{;
			config.method = method;		

			if (data) {
				config.body = JSON.stringify(data);
			}

			fetch(url, config).then((resp) => {
				if (!resp.ok) {
					throw resp;
				}
				
				if (resp.status < 200 || resp.status >= 400) {
					handleError(rej,data);
					return;
				}
				resp.json().then((data)=> {
					if (resp.status < 200 || resp.status >= 400) {
						handleError(rej,data);
						return;
					}
					res(data);
				}).catch(err => {
					handleError(rej,err);
					return;
				});   
				
				          
			}).catch(err => {
				if (config.dont_catch) {
					rej(err);
				}
				handleError(rej,err);
			}).finally(()=>{
				if (!config.no_feedback) {
					let event = new CustomEvent("loading_complete", {detail: url});
					window.dispatchEvent(event);
				}
			});
		});    
	});
}

const handleError = (rej, error) => {
	if (error?.status == 403 && window.location.pathname != '/login') {
		let event = new Event("bad_ticket");
  		window.dispatchEvent(event);
	} else if (error?.status == 401) {
		let event = new Event("unauthorized");
  		window.dispatchEvent(event);
	} else if (error?.status == 500) {
		error.json().then((data)=> {
			window.dispatchEvent(new Event("unexpected_error"));
			try {
				//rej({code: error.status, data: JSON.parse(data)});
			} catch (e) {
				//rej({code: error.status, message: data});
			}
		});
	} else {
		try {
			error.text().then((data)=> {
				try {
					rej({code: error.status, data: JSON.parse(data)});
				} catch (e) {
					rej({code: error.status, message: data});
					return;
				}
			}).catch((err) => {
				rej({code: error.status, message: error});
			});
		} catch (e) {
			window.dispatchEvent(new Event("unexpected_error"));
		}
			
		
	}
}

const get = function(url, params, options) {
	if (params) {
		var vars = Object.keys(params).map((k,i)=>{
			return k+'='+encodeURIComponent(params[k]);
		});

		var query = vars.join('&');

		url += (url.indexOf('?') > 10 ? '&' : '?')+query;
	}

	return issue_request('GET',url, null, options);
}



export default {
    get: get,
	getConfig: getConfig,
	post: function(url, data, opts) {
		return issue_request('POST',url, data, opts);        
	},
	put: function(url, data, opts) {
		return issue_request('PUT',url, data, opts);        
	},
	patch: function(url, data, opts) {
		return issue_request('PATCH',url, data, opts);        
	},
	delete: function(url, data, opts) {
		return issue_request('DELETE',url, data, opts);        
	}
}
