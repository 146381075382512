import { AccessTime, Add, AddCircleOutline, AddOutlined, AddTask, AssignmentTurnedIn, Beenhere, Cancel, Check, Clear, Close, Delete, DoneAll, HowToReg, Info, InfoOutlined, Medication, MilitaryTech, Save, School, Star, StarHalf, TaskAlt, Update, WhereToVote } from '@mui/icons-material';
import { Filter1, Filter2, Filter3, Filter4, Filter5, Filter6, Filter7, Filter8, Filter9 } from '@mui/icons-material';
import { Badge, Button, ButtonGroup, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, lighten, LinearProgress, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, MenuItem, OutlinedInput, Select, TextField, ToggleButton, ToggleButtonGroup, Tooltip, alpha } from '@mui/material';
import { Box, IconButton, Stack, styled } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm, set } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ColorInput from '../components/ColorInput';
import jax from '../helper/jax';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Paper } from '@mui/material';
import { Divider } from '@mui/material';
import Notification from '../components/Notification';
import { setBadges as setBadgeData, setTracks as setTrackData} from '../features/data';
import { useTheme } from '@emotion/react';
import certs from '../../functions/controllers/certs';
import ActionSet from './settings/ActionSet.tsx';
import { asDate } from '../helper/util.js';

const StyledIcon = styled(IconButton)((props:any) => ({
	
	padding: 2,
	'&.new': {
		border: 'solid 2px #ccc',
	},
	'& svg': {
		height: '1.5rem',
		width: '1.5rem',
	},
	'&:not(.new)': {
		cursor: props.onClick ? 'pointer' : 'auto',
		backgroundColor: props.color,
		backgroundImage: props.shade && 'linear-gradient(235deg, #ffd17aad, #00000000)',
		color: 'white',
	},
	'&.MuiIconButton-sizeLarge svg': {
		height: '1.75rem',
		width: '1.75rem',
		padding: '6px'
	},
	'&.MuiIconButton-sizeSmall svg': {
		height: '1rem',
		width: '1rem',
		padding: '4px'
	}

	
})); 



const BadgeIcons = {
	Check : <Check/>,
	DoneAll: <DoneAll/>,
	TaskAlt: <TaskAlt/>,
	AddTask: <AddTask/>,
	StarHalf: <StarHalf/>,
	Star: <Star/>,
	Add: <Add/>,
	AddCircleOutline: <AddCircleOutline/>,
	AssignmentTurnedIn: <AssignmentTurnedIn/>,
	School: <School/>,
	HowToReg: <HowToReg/>,
	WhereToVote: <WhereToVote/>,
	Close: <Close/>,
	AccessTime: <AccessTime/>,
	Update: <Update/>,
	
	Medication: <Medication/>,
	MilitaryTech: <MilitaryTech/>,
	Filter1: <Filter1/>,
	Filter2: <Filter2/>,
	Filter3: <Filter3/>,
	Filter4: <Filter4/>,
	Filter5: <Filter5/>,
	Filter6: <Filter6/>,
	Filter7: <Filter7/>,
	Filter8: <Filter8/>,
	Filter9: <Filter9/>
}

const BadgeIcon = (props: any) => {
	const {color, tooltip, icon, isNew, shade, pending, ...other} = props;
	var icn = <StyledIcon {...other} shade={!pending && shade !== false} color={pending ? '#ccc' : color} className={isNew ?"new" : ""}>
		{isNew ? <Add/> : BadgeIcons[icon] || props.children || <Star/>}
	</StyledIcon>;

	if (tooltip) {
		return <Tooltip title={tooltip}>{icn}</Tooltip>
	}
	return icn;
}

const Tracks: React.FC = () => {

	const {terms} = useSelector((state: any) => state.app); 
	const [editBadge, setEditBadge] = React.useState<any>(null);
	const [editTrack, setEditTrack] = React.useState<any>(null);
	const [badges, setBadges] = React.useState<any>([]);
	const [tracks, setTracks] = React.useState<any>([]);
	
	const dispatch = useDispatch();
	

	useEffect(() => {
		jax.get('/app/admin/badges').then((data) => {	
			setBadges(data.badges);
			setTracks(data.tracks);
		});
	}, []);

	const saveBadge = (badge: any) => {
		jax.post('/app/admin/badges', badge).then((data) => {
			//Update or insert the badge by id in the badges array
			let idx = badges.findIndex((b:any) => b.id === data.id);
			if (idx >= 0) {
				badges[idx] = data;
			} else {
				badges.push(data);
			}
			setBadges([...badges]);
			dispatch(setBadgeData(badges));
		});
	}

	const saveTrack = (track: any) => {
		jax.post('/app/admin/tracks', track).then((data) => {
			//Update or insert the badge by id in the badges array
			let idx = tracks.findIndex((b:any) => b.id === data.id);
			if (idx >= 0) {
				tracks[idx] = data;
			} else {
				tracks.push(data);
			}
			setTracks([...tracks]);
			dispatch(setTrackData(tracks));
		});
	}

	return <>
		<Box p={2}>
			{/* <h1>{terms["BADGE"].p} &amp; {terms["TRACK"].p}</h1> */}
			<Divider sx={{mt:3, mb:2}}><b>{terms["BADGE"].p}</b></Divider>
			
			<Box mt={1}>{terms["BADGE"].p} are useful in tracking milestones or accomplishments for {terms["USER"].p.toLowerCase()} through the course of their training.  They can be used to restrict enrollment in specialized courses based on pre-requisite completion.</Box>
			
			<Grid2 container spacing={2} mt={2}>
				{badges.map((b,i) =>{
					return <Grid2 xs={2} md={2} lg={1} key={i}>
						<Stack spacing={1} alignItems="center" flex={1}>
							<BadgeIcon size="large" color={b.color} tooltip={b.description} icon={b.icon} onClick={()=>setEditBadge(b)}><Star/></BadgeIcon>
							<Box textAlign="center" className="sub-title xs">{b.name}</Box>
						</Stack>
					</Grid2>
				})}
				<Grid2 xs={2} md={2} lg={1} >
					<Stack spacing={1} alignItems="center" flex={1}>
						<BadgeIcon size="large" isNew tooltip={`Add a new ${terms["BADGE"].s}`} onClick={()=>setEditBadge({name: `New ${terms["BADGE"].s}`, color: '#FF0000', icon: "Check"})}></BadgeIcon>
						<Box textAlign="center" className="sub-title xs">New {terms["BADGE"].s}</Box>
					</Stack>
				</Grid2>
			</Grid2>

			{/* <Stack direction="row" spacing={1} mt={2} justifyContent="flex-start" flexBasis={12}>
				{badges.map((b,i) =>{
					return <Stack spacing={1} alignItems="center" flex={1}>
						
						<BadgeIcon size="large" color={b.color} tooltip={b.description} onClick={()=>setEditBadge(b)}><Star/></BadgeIcon>
						<div className="sub-title xs">{b.name}</div>
						
					</Stack>;
				})}
				<Stack spacing={1} alignItems="center" flex={1}>
					<BadgeIcon size="large" isNew tooltip={`Add a new ${terms["BADGE"].s}`} onClick={()=>setEditBadge({name: 'New Badge'})}></BadgeIcon>
					<div className="sub-title xs">New {terms["BADGE"].s}</div>
				</Stack>
			</Stack> */}
			<Divider sx={{mt:3, mb:2}}><b>{terms["TRACK"].p}</b></Divider>
			
			<Box mt={1}>{terms["TRACK"].p} are a series of {terms["BADGE"].p.toLowerCase()} that a indicate {terms["USER"].s.toLowerCase()} progress toward a specific goal.  When a {terms["USER"].s.toLowerCase()} possesses all {terms["BADGE"].p.toLowerCase()} in a {terms["TRACK"].s.toLowerCase()}, it is considered complete.  New {terms["CERTIFICATION"].p} and / or {terms["ROLE"].p.toLowerCase()} can be automatically assigned when a track has been completed.</Box>
			
			<Stack spacing={1} alignItems="center" flex={1} mt={2} >
				<Grid2 container alignItems="stretch" justifyContent="stretch" spacing={2}>
					{tracks.map((t,i) => {	
						return <Grid2 lg={6} xs={12} key={i}>
							<Paper variant="outlined" sx={{height:'100%'}}>
								<List dense sx={{height:"100%"}}>
									
									<ListItem  sx={{p:0, height:"100%"}}>
										<ListItemButton sx={{height:"100%"}} onClick={()=>setEditTrack(t)}>
											<Stack direction="row" spacing={2} flex={1} alignItems="center" my={1} justifyContent="stretch" sx={{height:"100%"}}>
												<Stack direction="row" spacing={2} alignItems="center" flex={1} flexShrink={0}>
													<Box my={1}>
														<ListItemAvatar sx={{flex:0, minWidth:'unset'}} >
															<TrackIcon size="48px" color={t.color} tooltip={t.description} progress={65}/>
														</ListItemAvatar>
													</Box>
													<ListItemText  primary={t.name} secondary={t.description} primaryTypographyProps={{fontSize:"1rem", fontWeight: "bold", flex:1}}>
													</ListItemText>
												</Stack>
												<Stack direction="row"  spacing={-1}>
													{t.badges?.map((b, j) => {
														var bg= badges.find((badge:any) => badge.id === b);
														return <BadgeIcon size="large" color={bg.color} icon={bg.icon} tooltip={bg.name} sx={{border:'solid 1px white'}}/>
													})}
												</Stack>
											</Stack>
										</ListItemButton>
									</ListItem>
									
								</List>
							</Paper>
						</Grid2>
					})}
					<Grid2 lg={6} xs={12} spacing={2} sx={{flex:1}}>
						<Paper variant="outlined" sx={{height:'100%'}}>
							<List dense sx={{height:"100%"}}>
							<ListItem  sx={{p:0,height:"100%"}}>
									<ListItemButton sx={{height:"100%", opacity:0.6, "&:hover":{opacity:1}}} onClick={()=>setEditTrack({name: 'New Track'})}>
										<Stack direction="row" height="100%" spacing={2} flex={1} alignItems="center" justifyContent="stretch">
											<Stack direction="row" spacing={2} alignItems="center" flex={1} py={3} flexShrink={0}>
												<ListItemAvatar sx={{flex:0, minWidth:'unset'}}>
													<TrackIcon size="48px" color={"#666666"}  progress={65}/>
												</ListItemAvatar>
												<ListItemText  primary={`Create a new ${terms["TRACK"].s}`} primaryTypographyProps={{fontSize:"1rem", fontWeight: "bold", flex:1}}>
												</ListItemText>
											</Stack>
											<Stack direction="row"  spacing={-1}>
												
													<BadgeIcon size="large" shade={false} color={"#666"}  sx={{border:'solid 1px white'}}/>
												
											</Stack>
										</Stack>
									</ListItemButton>
								</ListItem>
							</List>
						</Paper>
					</Grid2>
				</Grid2>
			</Stack>
		</Box>

		<BadgeDialog badge={editBadge} open={editBadge != null} onSave={saveBadge} onClose={()=>setEditBadge(null)}/>
		<TrackDialog track={editTrack} open={editTrack != null} onSave={saveTrack} onClose={()=>setEditTrack(null)}/>
	</>;
};

const BadgeDialog: React.FC = (props: any) => {
	const {badge, open, onSave, onClose} = props;

	const {handleSubmit, control, watch, reset, setValue} = useForm({
		defaultValues: {name: '', description: '', icon: 'Star', color: '#FF0000', ...badge}
	});
	const theme = useTheme();
	
	const {terms} = useSelector((state: any) => state.app);
	useEffect(() => {
		if (badge) {
			reset(badge);
		}
	}, [badge]);

	return <Dialog  fullWidth maxWidth="xs"
		open={open} 
		onClose={onClose}
		PaperProps={{
			component: 'form',
			onSubmit: handleSubmit((data)=>{
				onSave(data);
				onClose();	
			})
		  }}>
		<Box className="card-header" sx={{padding:1, font:""}}>
		  {badge?.id ? `Edit ${terms["BADGE"].s}` : `Create a New ${terms["BADGE"].s}`}
		</Box>
		<DialogContent dividers sx={{padding:0}}>
		<Stack p={2} spacing={3}>
			
			<FormControl fullWidth size="small" >
		  		<Controller control={control} name="name" render={({field}) => <TextField {...field} label={`${terms["BADGE"].s} Name`} size="small"/>} /> 
			</FormControl>

			<FormControl fullWidth size="small" >
		  		<Controller control={control} name="description" render={({field}) => <TextField multiline rows={2} {...field} label="Description" size="small"/>} /> 
			</FormControl>
			
			<Divider>Color and Icon</Divider>
			<Stack direction="row" spacing={2} alignItems="stretch">
				
				<Box flex={1} height="200px" pr={1} sx={{overflowY:'auto', overflowX:'hidden'}} borderRadius={1} p={0.5} border={`solid 1px ${theme.palette.divider}`}>
					<FormControl fullWidth size="small" variant='outlined'>
						
						<Controller control={control} name="icon" render={({field}) => {
							return <ToggleButtonGroup size="small" exclusive {...field} onChange={(e,v)=>setValue("icon", v)}>
								<Grid2 container spacing={1}>
									{Object.keys(BadgeIcons).map((icon, i) => {
										return <Grid2 xs={3}>
											<ToggleButton size="small" value={icon} selected={field.value == icon}>{BadgeIcons[icon]}</ToggleButton>
										</Grid2>
									})}
									
								</Grid2>
							</ToggleButtonGroup>
						}} />
					</FormControl>
				</Box>
				<Stack flex={1}>
					<FormControl fullWidth size="small" >
						<Controller control={control} name="color" render={({field}) => <ColorInput {...field} value={watch('color')} label="Color" size="small"/>} />
					</FormControl>
					<Stack flex={1} alignItems="center" justifyContent="center">
						<BadgeIcon size="large" color={watch('color')} icon={watch('icon')} onClick={()=>setValue("color", "#000000")}></BadgeIcon>
					</Stack>
				</Stack>
			</Stack>

		</Stack>
		</DialogContent>
		<DialogActions>
			<Stack direction="row" spacing={2} flex={1} justifyContent="space-between" >
				<Button size="small"  onClick={onClose}>Cancel</Button>
				<Button type="submit" size="small" variant="contained">Save</Button>
			</Stack>
		</DialogActions>
	</Dialog>
}

const TrackDialog: React.FC = (props: any) => {
	const {track, open, onSave, onClose} = props;

	const {badges} = useSelector((state: any) => state.data);
	const {handleSubmit, control, watch, reset, setValue} = useForm({
		defaultValues: {name: '', description: '', color: '#0000FF', badges:[], actions: [], ...track}
	});
	const theme = useTheme();
	const [newActionType, setNewActionType] = React.useState<any>("");
	const [newActionId, setNewActionId] = React.useState<any>("");
	const [addingAction, setAddingAction] = React.useState<any>(false);

	const {terms} = useSelector((state: any) => state.app);

	const [addBadgeEl, setAddBadgeEl] = React.useState<any>(null);
	useEffect(() => {
		if (track) {
			setNewActionType("");
			reset(track);
		}
	}, [track]);

	function addBadge(id) {
		var badges = watch('badges') || [];
		if (!badges.includes(id)) {
			badges.push(id);
			setValue('badges', badges);
		}
	}

	useEffect(() => {
		setNewActionId(null);
	}, [newActionType]);
 
	return <Dialog fullWidth maxWidth="md" keepMounted={false}
		open={open} 
		onClose={onClose}
		PaperProps={{
			component: 'form',
			onSubmit: handleSubmit((data)=>{
				onSave(data);
				onClose();	
			})
		  }}>
		<Box className="card-header" sx={{padding:1, font:""}}>
		  {track?.id ? `Edit ${terms["TRACK"].s}` : `Create a New ${terms["TRACK"].s}`}
		</Box>
		<DialogContent dividers sx={{padding:0}}>
			<Stack direction="row" spacing={0} alignItems="stretch" flex={1}>
				<Box flex={1}>
					<Stack p={2} spacing={2} flex={1}>
						
						<FormControl fullWidth size="small" >
							<Controller control={control} name="name" render={({field}) => <TextField {...field} label={`${terms["BADGE"].s} Name`} size="small"/>} /> 
						</FormControl>

						<FormControl fullWidth size="small" sx={{"& .MuiInputBase-root":{paddingRight:0, paddingLeft: 1, paddingBottom:0}, "& .MuiInputBase-input":{}}}>
							<Controller control={control} name="description" render={({field}) => <TextField multiline rows={3} {...field} label="Description" size="small"/>} /> 
						</FormControl>
						
						
						<Stack direction="row" spacing={1} alignItems="center">
							
							
							<Box flex={1}>
								<FormControl fullWidth size="small" >
									<Controller control={control} name="color" render={({field}) => <ColorInput {...field} value={watch('color')} label="Color" size="small"/>} />
								</FormControl>
							</Box>
							<Box flex={1}>
							<Box pl={1} textAlign="center">
								<TrackIcon size="32px" color={watch('color')} progress={65}/>
							</Box>
							</Box>
						</Stack>
					</Stack>
					<Divider>{terms["BADGE"].p}</Divider>
						
					<Box height="200px"  sx={{overflowY:'auto', overflowX:'hidden'}} p={0.5}>
						{track && <Grid2 container spacing={2} mt={1}>
							{watch('badges')?.map((b,i) =>{
								var badge = badges.find((badge:any) => badge.id === b);
								return <Grid2 xs={2} md={4} lg={4} key={i}>
									<Box>	
										<Stack spacing={1} alignItems="center" flex={1}>
											<Badge sx={{"& .MuiBadge-badge":{px:0, cursor:'pointer'}}} badgeContent={<Clear sx={{padding:0, fontSize:'1rem'}}/>} color="error" onClick={()=>{setValue("badges", watch('badges').filter((id:any)=>id !== b))}}>
												<BadgeIcon size="large" color={badge.color} tooltip={badge.description} icon={badge.icon} ><Star/></BadgeIcon>
											</Badge>
											<Box textAlign="center" className="sub-title xs">{badge.name}</Box>
										</Stack>
									</Box>
								</Grid2>
							})}
							<Grid2 xs={2} md={4} lg={4} >
								<Stack spacing={1} alignItems="center" flex={1}>
									<BadgeIcon size="large" isNew tooltip={`Add a new ${terms["BADGE"].s}`} onClick={(e)=>setAddBadgeEl(e.currentTarget)}></BadgeIcon>
									<Box textAlign="center" className="sub-title xs">Add {terms["BADGE"].s}</Box>
								</Stack>
							</Grid2>
						</Grid2>}
						<BadgeMenu badgeIds={badges.map(b=>b.id)} el={addBadgeEl} onClose={()=>setAddBadgeEl(null)} onSelect={(id: any)=>{addBadge(id); setAddBadgeEl(null)}}/>
					</Box>
				</Box>
				<Divider orientation="vertical" flexItem />
				<Stack flex={1}>
					<Box p={2} >
						<h4>{terms["TRACK"].s} Completion {terms["USER"].s} Action(s)</h4>
						<Box mt={0.5} className="sub-title">When a {terms["USER"].s.toLowerCase()} completes this {terms["TRACK"].s.toLowerCase()}, they will also be affected by the following actions.</Box>
					</Box>
					<Divider/>
					
					<ActionSet sx={{flex:1}} onChange={(actions)=>setValue('actions', actions)} actionSetId={track?.action_set_id}/>
				</Stack>
				
			</Stack>
		</DialogContent>
		<DialogActions>
			<Stack direction="row" spacing={2} flex={1} justifyContent="space-between" >
				<Button size="small"  onClick={onClose}>Cancel</Button>
				<Button type="submit" size="small" disabled={addingAction || !watch('badges')?.length  || !watch('name')} variant="contained">Save</Button>
			</Stack>
		</DialogActions>
	</Dialog>
}

const TrackIcon = (props: any) => {
	const {color, tooltip, progress, complete, size, ...other} = props;
	var icon = <Box position="relative" height={size || 28} width={size || 28}>
		<CircularProgress variant="determinate" size={size || 28} value={100} thickness={8} sx={{
			color: (theme) => color,
			opacity: 0.25,
			}} />
		<CircularProgress variant="determinate" size={size || 28} value={progress || 2} thickness={8} sx={{
			position: 'absolute',
			left:0,
			color: (theme) => color,
			}} />
		{complete && <Box position="absolute" top={0} left={0} width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
			<Check sx={{color: color, fontSize: '14px'}}/>
		</Box>}
	</Box>;

	if (tooltip) {
		return <Tooltip title={tooltip}>{icon}</Tooltip>
	}
	return icon;

}
const BadgeMenu = (props: any) => {
	const {badgeIds, el, onSelect, onClose} = props;

	const {badges : allBadges} = useSelector((state: any) => state.data);

	const [badges, setBadges] = React.useState<any>([]);
	useEffect(() => {
		if (badgeIds) {
			setBadges(allBadges.filter((b:any) => badgeIds.includes(b.id)));
		} else {
			setBadges([]);
		}
	}, [badgeIds, allBadges]);
	return <Notification over={true} anchorEl={el} open={!!el} ContainerProps={{p:0, maxWidth:300, maxHeight: 200, overflow:'auto'}} onClose={onClose}>
			<List dense sx={{p:0}}>
				{badges?.map((b,i) =>{
					return <ListItemButton key={i} onClick={()=>{onSelect(b.id); onClose()}} sx={{py:0, alignItems:'flex-start'}}>
						<ListItemAvatar sx={{minWidth:'unset', mr:1, mt:1}}>
							<BadgeIcon size="small" icon={b.icon} color={b.color} ></BadgeIcon>
						</ListItemAvatar>
							<ListItemText primary={b.name} secondary={b.description} secondaryTypographyProps={{className:"sub-title xs", textOverflow:"ellipsis", overflow:'hidden', sx: {
								display: "-webkit-box",
								textOverflow: "ellipsis",
								overflow: "hidden",
								WebkitLineClamp: "2",
								WebkitBoxOrient: "vertical"
							} }}/>
							
						</ListItemButton>
					
				})}
			</List>
		</Notification>
			};

const TrackMenu = (props: any) => {
	const {trackIds, el, onSelect, onClose} = props;

	const {tracks : allTracks} = useSelector((state: any) => state.data);

	const [tracks, setTracks] = React.useState<any>([]);
	useEffect(() => {
		if (trackIds) {
			setTracks(allTracks.filter((b:any) => trackIds.includes(b.id)));
		} else {
			setTracks([]);
		}
	}, [trackIds, allTracks]);
	return <Notification over={true} anchorEl={el} open={!!el} ContainerProps={{p:0, maxWidth:300, maxHeight: 200, overflow:'auto'}} onClose={onClose}>
			<List dense sx={{p:0}}>
				{tracks?.map((b,i) =>{
					return <ListItemButton key={i} onClick={()=>{onSelect(b.id); onClose()}} sx={{py:0, minWidth:'unset', alignItems:'flex-start'}}>
						<ListItemAvatar sx={{ minWidth:'unset', mr:1, mt:1}}>
							<TrackIcon size={16} icon={b.icon} progress={65} color={b.color} ></TrackIcon>
						</ListItemAvatar>
							<ListItemText primary={b.name} secondary={b.description} secondaryTypographyProps={{className:"sub-title xs", textOverflow:"ellipsis", overflow:'hidden', sx: {
								display: "-webkit-box",
								textOverflow: "ellipsis",
								overflow: "hidden",
								WebkitLineClamp: "2",
								WebkitBoxOrient: "vertical"
							} }}/>
							
						</ListItemButton>
					
				})}
			</List>
		</Notification>
};

const UserTracks = (props: any) => {

	const {user, canAdd, onAdd} = props;
	const {tracks, badges} = useSelector((state: any) => state.data);
	const {terms} = useSelector((state: any) => state.app);
	const [showTrackInfo, setShowTrackInfo] = React.useState<any>(null);
	const [showTrackMenuEl, setShowTrackMenuEl] = React.useState<any>(null);

	const addToTrack = (trackId) => {
		jax.post(`/app/tracks`,{
			track_id: trackId,
			user_id: user.uid
		}).then((data) => {
			if (onAdd) {
				onAdd(data);
			}
		});
	}

	return <><Stack flex={1} spacing={1.5} mt={1.5}>
											
		{user && user?.tracks?.map((k, i)=>{
			var progress = k;//user.tracks.find((x:any)=>x.track_id == k);
			var track = tracks.find(x=>x.id == k.track_id);
			return track && <Stack key={i} direction="row" alignItems="center" spacing={1}>
				<Box><TrackIcon color={track.color} complete={!!progress.completed} tooltip={`${progress.held} of ${progress.total} ${terms["BADGE"].p} completed`} progress={progress.held/progress.total*100}/> </Box>
				<Stack flex={1}>
					<Box>{track?.name}</Box>
					{!!progress.date_completed && <Box className="sub-title xs">Completed {asDate(progress.date_completed)}</Box>}
					{!progress.date_completed && <Box className="sub-title xs">{progress.held} of {progress.total} {terms["BADGE"].p} Completed</Box>}
					
				</Stack>
				<Box>
					<IconButton size="small" onClick={(e)=>{setShowTrackInfo({...progress, track: track, el: e.currentTarget})}}>
						<InfoOutlined color='disabled' sx={{fontSize: '01.5rem', opacity: 0.7, verticalAlign: 'middle', "&:hover": {opacity:1}}}/>
					</IconButton>
					
				</Box>
			</Stack>
		})}

		{canAdd && <Stack direction="row" alignItems="center" spacing={1} sx={{cursor:'pointer', opacity:0.65, "&:hover":{opacity:1}}} onClick={(e)=>{setShowTrackMenuEl(e.currentTarget)}}>
			<Stack position="relative" justifyContent="center" alignItems="center" >
				<TrackIcon color="#666" complete={false} progress={65}/>
				<Box position={'absolute'} top={0} left={0} width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
					<Add sx={{fontSize:"0.75rem"}}/>
				</Box>
			</Stack>
			<Stack flex={1}>
				<Box color="inherit">Add {terms["TRACK"].s}</Box>
				<Box className="sub-title xs"></Box>
			</Stack>

		</Stack>}

		<TrackMenu trackIds={tracks.map(t=>t.id)} el={showTrackMenuEl} onClose={()=>{setShowTrackMenuEl(null)}} onSelect={addToTrack}/>
	</Stack>
	<Notification open={showTrackInfo?.el} over={false} right={true} anchorEl={showTrackInfo?.el} onClose={()=>{setShowTrackInfo(null)}} >
		<Box p={0}>
			{showTrackInfo?.track && <Box >{showTrackInfo.track.name}</Box>}
			{showTrackInfo?.track && <LinearProgress variant="determinate" value={showTrackInfo.prog*100} sx={{backgroundColor: `color-mix(in srgb, ${showTrackInfo.track.color}, transparent 75%)`,  "& span": {opacity:1, backgroundColor: (theme) => showTrackInfo.track.color}}} />}
			{showTrackInfo?.el && <Box mt={0.5} textAlign="right" className="sub-title xs">{showTrackInfo.held} of {showTrackInfo.total} {terms["BADGE"].p.toLowerCase()} completed</Box>}
			<Stack spacing={0.5} mt={1}>
				{showTrackInfo?.track?.badges?.map((b,i) =>{
					var b = badges.find((badge:any) => badge.id === b);
					var has = !!user.badges && !!user.badges.find(x=>x.badge_id == b.id);
					return <Stack direction="row" alignItems="center" key={i} >
						<ListItemAvatar sx={{minWidth:'unset', mr:0.5, }} >
							<BadgeIcon size="small" icon={has ? b.icon : "Close"} color={b.color} pending={!has} sx={{transform: 'scale(0.75)'}}></BadgeIcon>
						</ListItemAvatar>
						<Stack>
							<Box fontSize="0.9rem" color={has ? '' : '#999'}>{b.name}</Box>
						</Stack>	
							
					</Stack>;					
					
				})}
			</Stack>
		</Box>
	</Notification></>;
}
			
export default Tracks;
export {BadgeIcon, TrackIcon, UserTracks, BadgeMenu};

